<template>
  <a-spin :spinning="loading">
    <a-card title="Sales Performance ($) (YTD)" size="small">
      <div ref="salesPerformanceChart"></div>
    </a-card>
  </a-spin>
</template>

<script>
import { Column } from "@antv/g2plot";

export default {
  name: "sales-performance-chart",

  props: ["chartData", "loading"],

  data() {
    return {
      chart: null,
      config: {
        xField: "month",
        yField: "amount",
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
        },
        yAxis: {
          label: {
            formatter: (v) => this.$comman.withCurrency(v),
          },
        },
        meta: {
          month: {
            alias: "Month",
          },
          amount: {
            alias: "Amount",
          },
        },
        tooltip: {
          formatter: (v) => ({
            name: "Amount",
            value: this.$comman.withCurrency(v.amount),
          }),
        },
        label: {
          position: "top",
          formatter: (v) => this.$comman.withCurrency(v.amount),
        },
        legend: false,
        smooth: true,
        animation: {
          appear: {
            animation: "path-in",
            duration: 5000,
          },
        },
        color: "#023958",
      },
    };
  },

  computed: {
    //
  },

  mounted() {
    //
  },

  methods: {
    updateChart() {
      let amounts = this.chartData.map((e) => e.amount);
      let maxAmount = Math.max(...amounts);
      let max = Math.round(maxAmount + maxAmount * 0.1);
      let data = {
        data: this.chartData,
        ...this.config,
        yAxis: {
          max,
        },
      };
      if (this.chart) {
        this.chart.update(data);
      } else {
        this.chart = new Column(this.$refs["salesPerformanceChart"], data);
        this.chart.render();
      }
    },
  },

  watch: {
    chartData: {
      deep: true,
      handler: function () {
        this.updateChart();
      },
    },
  },
};
</script>
