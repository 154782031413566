<template>
  <div>
    <a-form
      name="hospital_service_form"
      ref="hospital_service_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-row :gutter="[20, 0]">
        <a-col :xs="24" :lg="12">
          <a-form-item label="Case Service" name="case_service_id">
            <a-select
              v-model:value="formState.case_service_id"
              placeholder="Select service"
              optionFilterProp="label"
              :showSearch="true"
              width="100%"
            >
              <a-select-option
                v-for="option in caseServices"
                :key="option.id"
                :label="option.name"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :xs="24" :lg="12">
          <a-form-item label="Type" name="type">
            <a-select
              v-model:value="formState.type"
              placeholder="Select one..."
              optionFilterProp="label"
              showSearch
              width="100%"
            >
              <a-select-option
                v-for="option in $constants.hospitalCaseServiceTypes"
                :key="option.value"
                :label="option.text"
                :value="option.value"
              >
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :xs="24" :lg="12">
          <a-form-item label="Hospital Price" name="price">
            <a-input-number
              v-model:value="formState.price"
              placeholder="Enter here..."
              :min="0"
              :step="0.01"
              :formatter="$comman.currencyformatter"
              :parser="$comman.currencyParser"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :lg="12">
          <a-form-item
            ref="quickbooks_item_id"
            label="QuickBooks Item"
            name="quickbooks_item_id"
          >
            <a-select
              v-model:value="formState.quickbooks_item_id"
              placeholder="Select quickBooks item"
              optionFilterProp="label"
              showSearch
            >
              <a-select-option
                v-for="inventory in quickbooksServices"
                :key="inventory.Id"
                :label="inventory.Name"
                :value="inventory.Id"
              >
                {{ inventory.Name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :xs="24" :lg="12">
          <a-form-item label="Billing Code" name="billing_code">
            <a-input
              v-model:value="formState.billing_code"
              placeholder="Enter here..."
            />
          </a-form-item>
        </a-col>
      </a-row>

      <side-drawer-buttons :name="submitButtonText" />
    </a-form>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import { validation } from "../../../helper/validation";
import formMixins from "../../../mixins/formMixins";

export default {
  components: {
    PlusOutlined,
  },

  data() {
    return {
      formState: {},
      rules: {
        case_service_id: [
          {
            required: true,
            message: "This field is required!",
            type: "number",
          },
        ],
        type: [
          {
            required: true,
            message: "This field is required!",
            type: "number",
          },
        ],
        price: [
          {
            required: true,
            message: "This field is required!",
          },
          {
            message: "price have max upto 2 decimal points!",
            validator: validation.decimal,
            type: "number",
          },
        ],
      },
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    caseServices() {
      return this.extra.caseServices;
    },

    quickbooksServices() {
      return this.extra.quickbooksServices;
    },

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        hospital_id: this.record.hospital_id,
        id: this.record.id ?? null,
        case_service_id: this.record.case_service_id ?? undefined,
        type: this.record.type ?? undefined,
        price: this.record.price ? Number(this.record.price) : "",
        billing_code: this.record.billing_code ? this.record.billing_code: "",
        quickbooks_item_id: this.record.quickbooks_item_id ?? undefined,
      };
    },

    onSubmit() {
      let val = this.formState;
      if (val.quickbooks_item_id) {
        let selectedQuickbookItem = this.quickbooksServices.find(
          (v) => v.Id == val.quickbooks_item_id
        );
        if (selectedQuickbookItem) {
          val.quickbooks_item_info = selectedQuickbookItem;
        }
      }

      this.loadingStart();
      companyService
        .store(this.$constants.hospitalCaseServiceStoreUrl, val)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },
  },
};
</script>

<style></style>
