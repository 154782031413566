<template>
  <a-layout-content>
    <page-header>
      <template #title> File Compliance</template>

      <template #buttons>
        <a-button
          @click="handleExportExcelEvent"
          :loading="excelLoading"
          type="primary"
        >
          <a-space>
            <i class="ti ti-file-spreadsheet ti-lg"></i> Export Excel
          </a-space>
        </a-button>
      </template>
    </page-header>

    <a-row :gutter="[16, 0]" class="mb-sm-1">
      <a-col :xs="8">
        <a-spin :spinning="empNonCompliantChartLoading">
          <a-card title="Employees Non-Compliant Stats" size="small">
            <div ref="empNonCompliantChartRef"></div>
          </a-card>
        </a-spin>
      </a-col>

      <a-col :xs="8">
        <a-spin :spinning="contNonCompliantChartLoading">
          <a-card title="Contractors Non-Compliant Stats" size="small">
            <div ref="contNonCompliantChartRef"></div>
          </a-card>
        </a-spin>
      </a-col>

      <a-col :xs="8">
        <a-spin :spinning="allUserNonCompliantChartLoading">
          <a-card title="All Users Non-Compliant Stats" size="small">
            <div ref="allUserNonCompliantChartRef"></div>
          </a-card>
        </a-spin>
      </a-col>
    </a-row>

    <a-card size="small">
      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="fetchData"
        size="small"
        :scroll="{ x: true, y: 0 }"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #employee="{ record, text }">
          <router-link
            :to="{
              name: 'show-employee',
              params: { id: record.id },
              query: { tab: 'certificates' },
            }"
            target="_blank"
          >
            <a-typography-link>
              {{ text }}
            </a-typography-link>
          </router-link>
        </template>

        <template #missing_credentialing="{ record }">
          <template v-for="cred in record.missing_credentialing" :key="cred">
            <span>
              {{ cred }}
            </span>
            <br />
          </template>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { commonService } from "@/services";
import { Pie } from "@antv/g2plot";
import { mapActions } from "vuex";
import xFilterIcon from "../../../components/table/filterIcon.vue";
import xFilterInputSearchDropdown from "../../../components/table/filterInputSearchDropdown.vue";

export default {
  components: { xFilterIcon, xFilterInputSearchDropdown },
  data() {
    return {
      url: this.$constants.employeeCompletenessOfPersonnelFileUrl,
      dataSource: [],
      datatableLoading: false,
      excelLoading: false,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      showSizeChanger: true,
      pageSize:
        this.$route.query && this.$route.query.pageSize
          ? Number(this.$route?.query?.pageSize)
          : 30,
      pagination: {
        current:
          this.$route.query && this.$route.query.page
            ? Number(this.$route?.query?.page)
            : 1,
        size: "normal",
        pageSize:
          this.$route.query && this.$route.query.pageSize
            ? Number(this.$route?.query?.pageSize)
            : 30,
        total: 0,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showSizeChanger: true,
      },
      columns: [
        {
          title: "Employee",
          dataIndex: "name",
          onFilter: (value, record) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase()),
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "employee",
          },
        },
        {
          title: "Employment Type",
          dataIndex: "employment_type",
          key: "employment_type",
          filters: this.$constants.employment_types.map((item) => ({
            text: item.text,
            value: item.text,
          })),
          onFilter: (value, record) =>
            record.employment_type
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          filterMultiple: false,
        },
        {
          title: "Profession Type",
          dataIndex: "profession_type",
          key: "profession_type",
          filters: this.$constants.employee_types.map((item) => ({
            text: item.text,
            value: item.text,
          })),
          onFilter: (value, record) =>
            record.profession_type
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          filterMultiple: false,
        },
        {
          title: "Position",
          dataIndex: "position",
          key: "position",
          onFilter: (value, record) =>
            record.position
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Missing Credentialing",
          dataIndex: "missing_credentialing",
          onFilter: (value, record) =>
            record.missing_credentialing
              .join(", ")
              .toLowerCase()
              .includes(value.toLowerCase()),
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "missing_credentialing",
          },
        },
      ],

      employees: 0,
      contractors: 0,

      // EMPLOYEE NON-COMPLIANT STATS PIE CHART
      empNonCompliantChartLoading: true,
      empNonCompliantChartData: [],
      empNonCompliantChart: null,
      empNonCompliantChartConfig: {
        appendPadding: 10,
        angleField: "value",
        colorField: "type",
        radius: 0.9,
        color: ["#f0595e", "#63DAAB"],
        legend: {
          position: "top",
          maxRow: 4,
          flipPage: false,
          itemValue: {
            formatter: (text) => {
              let value =
                this.empNonCompliantChartData.find((e) => e.type == text)
                  ?.value ?? "0";
              return `(${value})`;
            },
            style: {
              opacity: 0.65,
            },
          },
        },
        label: {
          type: "inner",
          offset: "10%",
          content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            fontSize: 14,
            textAlign: "center",
          },
        },
        interactions: [{ type: "element-active" }],
      },

      // CONTRACTOR NON-COMPLIANT STATS PIE CHART
      contNonCompliantChartLoading: true,
      contNonCompliantChartData: [],
      contNonCompliantChart: null,
      contNonCompliantChartConfig: {
        appendPadding: 10,
        angleField: "value",
        colorField: "type",
        radius: 0.9,
        color: ["#f0595e", "#63DAAB"],
        legend: {
          position: "top",
          maxRow: 4,
          flipPage: false,
          itemValue: {
            formatter: (text) => {
              let value =
                this.contNonCompliantChartData.find((e) => e.type == text)
                  ?.value ?? "0";
              return `(${value})`;
            },
            style: {
              opacity: 0.65,
            },
          },
        },
        label: {
          type: "inner",
          offset: "10%",
          content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            fontSize: 14,
            textAlign: "center",
          },
        },
        interactions: [{ type: "element-active" }],
      },

      // ALL USER NON-COMPLIANT STATS PIE CHART
      allUserNonCompliantChartLoading: true,
      allUserNonCompliantChartData: [],
      allUserNonCompliantChart: null,
      allUserNonCompliantChartConfig: {
        appendPadding: 10,
        angleField: "value",
        colorField: "type",
        radius: 0.9,
        color: ["#f0595e", "#63DAAB"],
        legend: {
          position: "top",
          maxRow: 4,
          flipPage: false,
          itemValue: {
            formatter: (text) => {
              let value =
                this.allUserNonCompliantChartData.find((e) => e.type == text)
                  ?.value ?? "0";
              return `(${value})`;
            },
            style: {
              opacity: 0.65,
            },
          },
        },
        label: {
          type: "inner",
          offset: "10%",
          content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            fontSize: 14,
            textAlign: "center",
          },
        },
        interactions: [{ type: "element-active" }],
      },
    };
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleDatatableSearch({ selectedKeys, confirm, dataIndex }) {
      confirm();
    },

    handleDatatableReset({ clearFilters }) {
      clearFilters();
    },

    handleTableChange(pagination, filters, sorter) {
      const filteredData = this.dataSource.filter((item) => {
        Object.keys(filters).every((key) => {
          if (filters[key].length) {
            return filters[key].includes(item[key]);
          }
          return true;
        });
      });
      const length = filteredData.length;

      this.pagination = pagination;
      this.pagination.total = length;
      this.filterParams = {
        page: pagination.current,
        pageSize: pagination.pageSize,
        sortField: sorter.order ? sorter.field : undefined,
        sortOrder: sorter.order,
      };
      if (this.activeTabKey) {
        this.filterParams.status = Number(this.activeTabKey);
      }
    },

    fetchData() {
      this.datatableLoading = true;

      commonService
        .get(this.url, this.filterParams)
        .then(async (res) => {
          this.dataSource = res.data.certificates;
          this.pagination.total = res.data.certificates.length;
          let employees = res.data.employees;
          let contractors = res.data.contractors;

          this.empNonCompliantChartLoading = false;
          let nonCompliantEmployees = this.dataSource.filter(
            (e) => e.employment_type == "Employee"
          ).length;
          this.empNonCompliantChartData = [
            {
              type: "Non-Compliant",
              value: nonCompliantEmployees,
            },
            { type: "Compliant", value: employees - nonCompliantEmployees },
          ];
          if (this.empNonCompliantChart) {
            this.empNonCompliantChart.changeData(this.empNonCompliantChartData);
          } else {
            this.empNonCompliantChart = new Pie(
              this.$refs["empNonCompliantChartRef"],
              {
                data: this.empNonCompliantChartData,
                ...this.empNonCompliantChartConfig,
              }
            );
            await this.$nextTick(() => {
              this.empNonCompliantChart.render();
            });
          }

          this.contNonCompliantChartLoading = false;
          let nonCompliantContractors = this.dataSource.filter(
            (e) => e.employment_type == "Contractor"
          ).length;
          this.contNonCompliantChartData = [
            {
              type: "Non-Compliant",
              value: nonCompliantContractors,
            },
            {
              type: "Compliant",
              value: contractors - nonCompliantContractors,
            },
          ];
          if (this.contNonCompliantChart) {
            this.contNonCompliantChart.changeData(
              this.contNonCompliantChartData
            );
          } else {
            this.contNonCompliantChart = new Pie(
              this.$refs["contNonCompliantChartRef"],
              {
                data: this.contNonCompliantChartData,
                ...this.contNonCompliantChartConfig,
              }
            );
            await this.$nextTick(() => {
              this.contNonCompliantChart.render();
            });
          }

          this.allUserNonCompliantChartLoading = false;
          let nonCompliantUsers = this.dataSource.length;
          this.allUserNonCompliantChartData = [
            {
              type: "Non-Compliant",
              value: nonCompliantUsers,
            },
            {
              type: "Compliant",
              value: employees + contractors - nonCompliantUsers,
            },
          ];
          if (this.allUserNonCompliantChart) {
            this.allUserNonCompliantChart.changeData(
              this.allUserNonCompliantChartData
            );
          } else {
            this.allUserNonCompliantChart = new Pie(
              this.$refs["allUserNonCompliantChartRef"],
              {
                data: this.allUserNonCompliantChartData,
                ...this.allUserNonCompliantChartConfig,
              }
            );
            await this.$nextTick(() => {
              this.allUserNonCompliantChart.render();
            });
          }
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    handleExportExcelEvent() {
      this.excelLoading = true;

      commonService
        .downloadFile(
          this.$constants.employeeCompletenessOfPersonnelFileUrl,
          { export_excel: true },
          "personnel_file_incomplete.xlsx"
        )
        .then((res) => {
          this.$message.success(res);
          this.excelLoading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.excelLoading = false;
        });
    },
  },
};
</script>
