<template>
  <a-card title="Email Notification" size="small" :loading="loading">
    <a-row :gutter="[16, 16]">
      <template v-for="(data, index) in settingData" :key="data.type">
        <setting-vue
          :notificationSettings="notificationSettings"
          :data="data"
          :hospitalId="parseInt($route.params.id)"
          :hospitalCaseServices="hospitalCaseServices"
          @updateSettings="(data) => (notificationSettings = data)"
        />

        <a-divider v-if="index + 1 != settingData.length" class="my-sm-0" />
      </template>
    </a-row>
  </a-card>
</template>

<script>
import { commonService } from "../../../services";
import settingVue from "../components/setting.vue";

export default {
  components: { settingVue },

  data() {
    return {
      loading: true,
      notificationSettings: [],
      settingData: this.$constants.hospitalNotificationSettings,
      hospitalCaseServices: [],
    };
  },

  async mounted() {
    await this.getHospitalCaseServices();
    this.fetchData();
  },

  methods: {
    getHospitalCaseServices() {
      return commonService
        .get(this.$constants.hospitalCaseServicesUrl, {
          hospital_id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.hospitalCaseServices = res.data;
        });
    },

    fetchData() {
      this.loading = true;
      commonService
        .store(this.$constants.hospitalGetNotificationUrl, {
          hospital_id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.notificationSettings = res.data;
        })
        .catch((err) => this.$message.error(err))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
