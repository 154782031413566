import { route } from "@/helper/route";
import auth from "./middleware/auth";
import hasRoles from "./middleware/hasRoles";
import profile from "./middleware/profile";

export default [
  {
    path: "/report/payroll",
    name: "report.payroll",
    component: route("report/payroll/index"),
    meta: {
      title: "Report - Payroll",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/report/time-tracking",
    name: "report.time-tracking",
    component: route("report/timeTracking/index"),
    meta: {
      title: "Report - Time Tracking",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/report/case-report-billing",
    name: "report.case-report-billing",
    component: route("report/caseReportBilling/index"),
    meta: {
      title: "Report - Case Report Billing",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/report/case-report",
    name: "report.case-report",
    component: route("report/caseReport/index"),
    meta: {
      title: "Report - Case Reports",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: [1, 3],
    },
  },
  {
    path: "/report/cell-saver-report",
    name: "report.cell-saver-report",
    component: route("report/cellSaverReport/index"),
    meta: {
      title: "Report - Cell Saver Reports",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/report/open-heart-report",
    name: "report.open-heart-report",
    component: route("report/openHeartQualityReport/index"),
    meta: {
      title: "Report - Open Heart Quality Reports",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/report/nrp-qi-report",
    name: "report.nrp-qi-report",
    component: route("report/nrpQualityReport/index"),
    meta: {
      title: "Report - NRP QI Reports",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/report/product-report",
    name: "report.product-report",
    component: route("report/productReport/index"),
    meta: {
      title: "Report - Product Reports",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/report/completeness-of-personnel-file",
    name: "report.completeness-of-personnel-file",
    component: route("report/compOfPersonnelFile/index"),
    meta: {
      title: "Report - Completeness of Personnel File",
      layout: "app",
      middleware: [auth, profile, hasRoles],
      roles: 1,
    },
  },
];
