<template>
  <a-card size="small">
    <a-row type="flex" :gutter="[16, 16]">
      <a-col :xs="12" :lg="6">
        <a-card :loading="loading">
          <a-statistic
            title="Total Sales (YTD)"
            :value="this.$comman.withCurrency(data?.sales_by_customer ?? 0)"
          >
            <template #suffix>
              <ShoppingFilled />
            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :xs="12" :lg="6">
        <a-card
          :loading="loading"
          @click="showDueInvoiceList"
          class="cursor-pointer"
        >
          <a-statistic
            title="Total Due (YTD)"
            :value="this.$comman.withCurrency(data?.total_due ?? 0)"
          >
            <template #suffix>
              <DollarCircleFilled />
            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :xs="12" :lg="6">
        <a-card
          :loading="loading"
          @click="showEmployeeList"
          class="cursor-pointer"
        >
          <a-statistic
            title="Credentialed Employees"
            :value="data?.working_employees?.length ?? 0"
          >
            <template #suffix>
              <ContactsFilled />
            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :xs="12" :lg="6">
        <a-card :loading="loading">
          <a-statistic
            title="No. of Cases (YTD)"
            :value="data?.no_of_cases ?? 0"
          >
            <template #suffix>
              <PlusCircleFilled />
            </template>
          </a-statistic>
        </a-card>
      </a-col>

      <a-col :xs="16">
        <a-spin :spinning="loading || salesPerformanceChartLoading">
          <a-card title="Sales Performance ($) (YTD)" size="small">
            <template #extra>
              <a-select
                v-model:value="salesPerformanceYear"
                style="width: 80px"
              >
                <a-select-option
                  v-for="year in $comman.getYearsListFrom()"
                  :key="year"
                  :value="year"
                >
                  {{ year }}
                </a-select-option>
              </a-select>
            </template>
            <a-space size="large" direction="vertical" style="width: 100%">
              <div
                class="sales-performance-chart hospital-finance-chart"
                id="salesPerformanceChart"
              ></div>
            </a-space>
          </a-card>
        </a-spin>
      </a-col>

      <a-col :xs="8">
        <a-spin :spinning="loading">
          <a-card title="Popular Case Services" size="small">
            <a-space size="large" direction="vertical" style="width: 100%">
              <div
                class="popular-case-services-pie-chart hospital-finance-chart"
                id="popularCaseServices"
              />
            </a-space>
          </a-card>
        </a-spin>
      </a-col>

      <a-col :xs="24">
        <a-card title="AR Aging Summary (YTD)" size="small">
          <a-table
            :columns="columns"
            :rowKey="(record, index) => index"
            :dataSource="data?.ar_aging_summary ?? []"
            :pagination="false"
            :loading="loading"
            size="small"
            :scroll="{ x: true, y: 0 }"
          />
        </a-card>
      </a-col>
    </a-row>
  </a-card>

  <a-spin :spinning="classWiseProfitAndLossLoading">
    <a-card class="mt-sm-2" title="Profit and Loss (YTD)" size="small">
      <template #extra>
        <a-space v-if="isQbClassMapped">
          <a-range-picker
            v-model:value="classWiseProfitAndLossDateRange"
            :allowClear="false"
            :ranges="ranges"
            style="width: 220px"
            :format="$constants.datepickerFormat"
          />

          <a-button
            type="primary"
            ghost
            :loading="downloadPdfBtnLoading"
            @click="downloadPdf()"
          >
            <a-space> <i class="ti ti-download ti-lg"></i> PDF </a-space>
          </a-button>

          <a-button
            type="primary"
            ghost
            :loading="downloadExcelBtnLoading"
            @click="downloadExcel"
          >
            <a-space> <i class="ti ti-download ti-lg"></i> Excel </a-space>
          </a-button>
        </a-space>
      </template>

      <template v-if="!isQbClassMapped">
        <a-alert
          message="Warning"
          description="Please map QuickBooks class for this facility."
          type="warning"
          show-icon
        />
      </template>

      <template v-else>
        <a-row :gutter="[16, 16]">
          <a-col :span="18">
            <table class="profit-and-loss-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th style="text-align: right">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in classWiseProfitAndLossData"
                  :key="`${index}_pl`"
                  :class="item.type"
                >
                  <profitAndLossAnalyticTableRows :item="item" />
                </tr>
              </tbody>
            </table>
          </a-col>

          <a-col :span="6">
            <a-space direction="vertical" style="width: 100%">
              <a-card>
                <a-statistic
                  title="Gross Profit (YTD)"
                  :value="grossProfitInPercentage"
                >
                </a-statistic>
              </a-card>

              <a-card>
                <a-statistic
                  title="Net Profit (YTD)"
                  :value="netProfitInPercentage"
                >
                </a-statistic>
              </a-card>

              <a-card>
                <a-statistic
                  title="Sale of Product Margin (%)"
                  :value="sopMargin"
                >
                </a-statistic>
              </a-card>
            </a-space>
          </a-col>
        </a-row>
      </template>
    </a-card>
  </a-spin>
</template>

<script>
import {
ContactsFilled,
DollarCircleFilled,
PlusCircleFilled,
SearchOutlined,
SettingFilled,
ShoppingFilled,
} from "@ant-design/icons-vue";
import { Column, Pie } from "@antv/g2plot";
import moment from "moment";
import { mapActions } from "vuex";
import { commonService } from "../../../services";
import profitAndLossAnalyticTableRows from "./profitAndLossAnalyticTableRows.vue";

export default {
  name: "hospital-analytics",

  props: {
    hospital: {
      type: Object,
      required: true,
    },
  },

  components: {
    SearchOutlined,
    ShoppingFilled,
    DollarCircleFilled,
    SettingFilled,
    ContactsFilled,
    PlusCircleFilled,
    profitAndLossAnalyticTableRows,
  },

  data() {
    return {
      columns: [
        {
          title: "#",
          dataIndex: "name",
          key: "name",
          customRender: () => "Amount",
        },
        {
          title: "Current",
          dataIndex: "current",
          key: "current",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "1-30 Days",
          dataIndex: "1_30_days",
          key: "1_30_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "31-60 Days",
          dataIndex: "31_60_days",
          key: "31_60_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "61-90 Days",
          dataIndex: "61_90_days",
          key: "61_90_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "Over 90 Days",
          dataIndex: "over_90_days",
          key: "over_90_days",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
        {
          title: "Total",
          dataIndex: "total",
          key: "total",
          customRender: ({ text }) =>
            text
              ? this.$comman.withCurrency(text)
              : this.$comman.withCurrency(0),
        },
      ],
      data: null,
      loading: true,
      downloadPdfBtnLoading: false,
      downloadExcelBtnLoading: false,
      salesPerformanceChartLoading: false,
      salesPerformanceYear: moment().format("YYYY"),

      salesPerformanceChart: null,
      salesPerformanceChartData: [],
      salesPerformanceChartConfig: {
        xField: "month",
        yField: "amount",
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
        },
        yAxis: {
          label: {
            formatter: (v) => this.$comman.withCurrency(v),
          },
        },
        meta: {
          month: {
            alias: "Month",
          },
          amount: {
            alias: "Amount",
          },
        },
        tooltip: {
          formatter: (v) => ({
            name: "Amount",
            value: this.$comman.withCurrency(v.amount),
          }),
        },
        label: {
          position: "top",
          formatter: (v) => this.$comman.withCurrency(v.amount),
        },
        legend: false,
        smooth: true,
        animation: {
          appear: {
            animation: "path-in",
            duration: 5000,
          },
        },
        color: "#023958",
      },

      popularCaseServicesChart: null,
      popularCaseServicesChartData: [],
      popularCaseServicesChartConfig: {
        forceFit: true,
        radius: 0.8,
        angleField: "value",
        colorField: "type",
        color: ["#3d8ebd", "#0076b2", "#023958", "#00a0dc"],
        label: {
          type: "inner",
          offset: "-30%",
          content: "{value}",
          style: {
            textAlign: "center",
            fontSize: 14,
          },
        },
        // show percentage in tooltip
        tooltip: {
          formatter: (v) => ({
            name: v.type,
            value: `${v.value}`,
          }),
        },
        statistic: {
          title: false,
          content: {
            style: {
              whiteSpace: "pre-wrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
            formatter: (v) => {
              const total = v.reduce((pre, now) => now.y + pre, 0);
              return `Total: ${total}`;
            },
          },
        },
        interactions: [{ type: "element-active" }],
        legend: {
          position: "top",
          flipPage: false,
        },
      },

      // PROFIT AND LOSS
      classWiseProfitAndLossData: [],
      classWiseProfitAndLossDateRange: [
        moment().startOf("year"),
        moment().endOf("year"),
      ],
      classWiseProfitAndLossLoading: true,
      profitAndLossColumns: [
        {
          title: "#",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          customRender: ({ text }) => this.$comman.currencyformatter(text),
        },
      ],
    };
  },

  computed: {
    isQbClassMapped() {
      return this.hospital?.quickbooks_class_id ? true : false;
    },

    netIncome() {
      return this.retriveName("Total Income");
    },

    netProfit() {
      return this.retriveName("Net Income");
    },

    grossProfit() {
      return this.retriveName("Gross Profit");
    },

    netProfitInPercentage() {
      return this.netIncome != 0
        ? `${parseFloat((this.netProfit / this.netIncome) * 100).toFixed(2)}%`
        : `${0}%`;
    },

    grossProfitInPercentage() {
      return this.netIncome != 0
        ? `${parseFloat((this.grossProfit / this.netIncome) * 100).toFixed(2)}%`
        : `${0}%`;
    },

    sopMargin() {
      let goodsSold = this.retriveName("55100 Cost of Products Sold");
      let productIncome = this.retriveName("48080 Sales of Product Income");
      return `${
        productIncome > 0
          ? Number(
              parseFloat(
                ((productIncome - goodsSold) / productIncome) * 100
              ).toFixed(2)
            )
          : 0
      }%`;
    },

    ranges() {
      let res = {};
      for (let index = 0; index < 5; index++) {
        res[moment().subtract(index, "years").startOf("year").format("YYYY")] =
          [
            moment().subtract(index, "years").startOf("year"),
            moment().subtract(index, "years").endOf("year"),
          ];
      }
      return res;
    },
  },

  async mounted() {
    await this.getHospitalAnalytics();
    await this.getClassWiseProfitAndLoss();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    getHospitalAnalytics() {
      this.loading = true;
      commonService
        .get(this.$constants.hospitalAnalyticsUrl, {
          hospital_id: this.hospital.id,
        })
        .then((res) => {
          this.data = res.data;
          this.popularCaseServicesChartData =
            this.data["popular_case_services"];
          this.salesPerformanceChartData = this.data["sales_performance"];
          this.data.ar_aging_summary = [this.data.ar_aging_summary];

          this.getPopularCaseServices();
          this.getSalesPerformance();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getClassWiseProfitAndLoss() {
      this.classWiseProfitAndLossLoading = true;
      commonService
        .get(this.$constants.hospitalClassWiseProfitAndLossAnalyticsUrl, {
          hospital_id: this.hospital.id,
          start_date: this.$customDate.ymd(
            this.classWiseProfitAndLossDateRange[0]
          ),
          end_date: this.$customDate.ymd(
            this.classWiseProfitAndLossDateRange[1]
          ),
        })
        .then((res) => {
          if (res.data?.Rows?.Row) {
            this.classWiseProfitAndLossData = this.makeProfitAndLossArray(
              res.data.Rows.Row
            ).flat(1);
          }
        })
        .finally(() => {
          this.classWiseProfitAndLossLoading = false;
        });
    },

    downloadPdf() {
      const dateRange = `${this.$customDate.mdy(
        this.classWiseProfitAndLossDateRange[0]
      )} - ${this.$customDate.mdy(this.classWiseProfitAndLossDateRange[1])}`;
      this.downloadPdfBtnLoading = true;

      commonService
        .downloadFile(
          this.$constants.hospitalClassWiseProfitAndLossAnalyticsUrl,
          {
            hospital_id: this.hospital.id,
            start_date: this.$customDate.ymd(
              this.classWiseProfitAndLossDateRange[0]
            ),
            end_date: this.$customDate.ymd(
              this.classWiseProfitAndLossDateRange[1]
            ),
            export_pdf: true,
          },
          `${this.hospital.name} - ProfitAndLoss - ${dateRange}.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.downloadPdfBtnLoading = false;
        });
    },

    downloadExcel() {
      const dateRange = `${this.$customDate.mdy(
        this.classWiseProfitAndLossDateRange[0]
      )} - ${this.$customDate.mdy(this.classWiseProfitAndLossDateRange[1])}`;
      this.downloadExcelBtnLoading = true;

      commonService
        .downloadFile(
          this.$constants.hospitalClassWiseProfitAndLossAnalyticsUrl,
          {
            hospital_id: this.hospital.id,
            start_date: this.$customDate.ymd(
              this.classWiseProfitAndLossDateRange[0]
            ),
            end_date: this.$customDate.ymd(
              this.classWiseProfitAndLossDateRange[1]
            ),
            export_excel: true,
          },
          `${this.hospital.name} - ProfitAndLoss - ${dateRange}.xlsx`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.downloadExcelBtnLoading = false;
        });
    },

    makeProfitAndLossArray(rows, indent = 0) {
      return rows.map((e) => {
        let temp = [];
        if (e.Header) {
          temp.push({
            name: e.Header.ColData[0].value,
            amount: 0,
            type: "header",
            indent,
          });
        }
        if (e.ColData) {
          temp.push({
            name: e.ColData[0].value,
            amount:
              e.ColData[1]?.value && e.ColData[1]?.value != ""
                ? e.ColData[1].value
                : 0,
            type: "col_data",
            indent,
          });
        }
        if (e?.Rows?.Row) {
          temp.push(this.makeProfitAndLossArray(e.Rows.Row, ++indent).flat());
        }
        if (e.Summary) {
          temp.push({
            name: e.Summary.ColData[0].value,
            amount:
              e.Summary.ColData[1]?.value && e.Summary.ColData[1]?.value != ""
                ? e.Summary.ColData[1].value
                : 0,
            type: "summary",
            indent,
          });
        }
        return temp;
      });
    },

    getHospitalSalesPerformanceAnalytics() {
      this.salesPerformanceChartLoading = true;
      let payload = {
        hospital_id: this.hospital.id,
        year: this.salesPerformanceYear,
      };
      commonService
        .get(this.$constants.hospitalSalesPerformanceAnalyticsUrl, payload)
        .then((res) => {
          this.salesPerformanceChartData = res.data;

          this.getSalesPerformance();
        })
        .finally(() => {
          this.salesPerformanceChartLoading = false;
        });
    },

    getSalesPerformance() {
      let amounts = this.salesPerformanceChartData.map((e) => e.amount);
      let maxAmount = Math.max(...amounts);
      let max = Math.round(maxAmount + maxAmount * 0.1);
      let data = {
        data: this.salesPerformanceChartData,
        ...this.salesPerformanceChartConfig,
        yAxis: {
          max,
        },
      };
      if (this.salesPerformanceChart) {
        this.salesPerformanceChart.update(data);
      } else {
        this.salesPerformanceChart = new Column("salesPerformanceChart", data);
        this.salesPerformanceChart.render();
      }
    },

    getPopularCaseServices() {
      if (this.popularCaseServicesChart) {
        this.popularCaseServicesChart.changeData(
          this.popularCaseServicesChartData
        );
      } else {
        this.popularCaseServicesChart = new Pie("popularCaseServices", {
          data: this.popularCaseServicesChartData,
          ...this.popularCaseServicesChartConfig,
        });
        this.popularCaseServicesChart.render();
      }
    },

    showEmployeeList() {
      if (!this.loading) {
        this.open({
          title: "Credentialed Employees",
          path: "hospital.drawerForm.credentialedEmployees",
          callback: () => {},
          record: this.data.working_employees,
        });
      }
    },

    showDueInvoiceList() {
      if (!this.loading) {
        this.open({
          title: "Due Invoice List",
          path: "hospital.drawerForm.dueInvoiceList",
          callback: () => {},
          record: this.hospital,
        });
      }
    },

    retriveName(name, data = this.classWiseProfitAndLossData) {
      return data.reduce((pre, curr) => {
        if (Array.isArray(curr)) {
          return pre + this.retriveName(name, curr);
        } else {
          return pre + (curr.name == name ? Number(curr.amount) : 0);
        }
      }, 0);
    },
  },

  watch: {
    salesPerformanceYear(curr, pre) {
      this.getHospitalSalesPerformanceAnalytics();
    },
    classWiseProfitAndLossDateRange() {
      this.getClassWiseProfitAndLoss();
    },
  },
};
</script>

<style lang="less">
.hospital-finance-chart {
  :not(div:last-child) {
    display: none;
  }
}

.profit-and-loss-table {
  width: 100%;

  thead {
    tr {
      th {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
      }
    }
  }

  tbody {
    tr.summary {
      td {
        border-top: 1px solid gray;
        font-weight: 500;
      }
    }
  }
}
</style>
