<template>
  <div>
    <a-form
      name="social_security_form"
      ref="social_security_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit()"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="12">
          <a-form-item
            ref="social_security_no"
            label="Social Security Number"
            name="social_security_no"
          >
            <a-input
              v-model:value="formState.social_security_no"
              placeholder="Enter social secutiry number here"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            ref="social_security_attachment"
            label="Social Security Attachment"
            name="social_security_attachment"
          >
            <a-upload
              :action="$constants.upload_action_url"
              list-type="picture"
              v-model:file-list="formState.social_security_attachment"
              @change="handleChangeSocialSecurityAttahcment"
              accept="image/jpeg,image/jpg,image/png,application/pdf"
            >
              <div>
                <a-button>
                  <UploadOutlined />
                  Upload
                </a-button>
              </div>
            </a-upload>
          </a-form-item>
        </a-col>
      </a-row>

      <a-divider />

      <a-row :gutter="[20, 0]">
        <a-col :span="12">
          <a-form-item
            ref="license_no"
            label="Driver's License Number"
            name="license_no"
          >
            <a-input
              v-model:value="formState.license_no"
              placeholder="Enter driver's license number here"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            ref="license_state"
            label="Driver's License State"
            name="license_state"
          >
            <a-input
              v-model:value="formState.license_state"
              placeholder="Enter driver's license state here"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            ref="license_attachment"
            label="Driver's License Attachment"
            name="license_attachment"
          >
            <a-upload
              :action="$constants.upload_action_url"
              list-type="picture"
              v-model:file-list="formState.license_attachment"
              @change="handleChangeLicenseAttachment"
              accept="image/jpeg,image/jpg,image/png,application/pdf"
            >
              <div>
                <a-button>
                  <UploadOutlined />
                  Upload
                </a-button>
              </div>
            </a-upload>
          </a-form-item>
        </a-col>
      </a-row>
      <a-button type="primary" html-type="submit" :loading="loading">
        Update
      </a-button>
    </a-form>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { UploadOutlined } from "@ant-design/icons-vue";
import { api } from "@/config/config";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "social_security",
  props: ["companies"],
  components: {
    UploadOutlined,
  },
  data() {
    return {
      loading: false,
      formState: {},
      rules: {
        social_security_no: [
          {
            required: true,
            message: "social security number is required!",
            trigger: "blur",
          },
        ],
        social_security_attachment: [
          {
            required: true,
            type: "array",
            message: "social security attachment is required!",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getFormState();
  },
  computed: {
    ...mapGetters("auth", ["employee"]),
  },
  methods: {
    ...mapActions("auth", ["update_employee"]),
    getFormState() {
      this.formState = {
        social_security_no: this.employee.social_security_no ?? "",
        social_security_attachment: this.employee.social_security_attachment
          ? [
              {
                uid: this.employee.id,
                status: "Done",
                name: this.employee.social_security_attachment.split(
                  "social_security_no/"
                )[1],
                url: api.host + "" + this.employee.social_security_attachment,
                thumbUrl:
                  api.host + "" + this.employee.social_security_attachment,
              },
            ]
          : [],
        license_no: this.employee.license_no ?? "",
        license_state: this.employee.license_state ?? "",
        license_attachment: this.employee.license_attachment
          ? [
              {
                uid: this.employee.id,
                status: "Done",
                name: this.employee.license_attachment.split(
                  "license_attachment/"
                )[1],
                url: api.host + "" + this.employee.license_attachment,
                thumbUrl: api.host + "" + this.employee.license_attachment,
              },
            ]
          : [],
      };
    },
    onSubmit() {
      this.$refs.social_security_form.validate().then((val) => {
        this.loading = true;
        companyService
          .store("api/employee/social-security-update", val)
          .then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              localStorage.setItem("employee", JSON.stringify(res.data));
              this.update_employee(res.data);
            }
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      });
    },
    handleChangeSocialSecurityAttahcment(info) {
      var isJPG = false;
      var isLt2M = false;
      var validEx = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
      if (info.file.status == "done") {
        isJPG = validEx.indexOf(info.file.type) == -1 ? false : true;
        isLt2M = info.file.size / 1024 / 1024 < 2;
        if (!isJPG) {
          this.$message.error(
            `You can only upload ${validEx.toString(",")} file!`
          );
        } else if (!isLt2M) {
          this.$message.error("Image must smaller than 2MB!");
        }
        if (isJPG && isLt2M) {
          this.getBase64(info.file.originFileObj, (base64Url) => {
            info.file.thumbUrl = base64Url;
          });
          this.formState.social_security_attachment = [info.file];
        } else {
          this.formState.social_security_attachment = info.fileList[1]
            ? [info.fileList[0]]
            : [];
        }
      }
    },
    handleChangeLicenseAttachment(info) {
      var isJPG = false;
      var isLt2M = false;
      var validEx = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
      if (info.file.status == "done") {
        isJPG = validEx.indexOf(info.file.type) == -1 ? false : true;
        isLt2M = info.file.size / 1024 / 1024 < 2;
        if (!isJPG) {
          this.$message.error(
            `You can only upload ${validEx.toString(",")} file!`
          );
        } else if (!isLt2M) {
          this.$message.error("Image must smaller than 2MB!");
        }
        if (isJPG && isLt2M) {
          this.getBase64(info.file.originFileObj, (base64Url) => {
            info.file.thumbUrl = base64Url;
          });
          this.formState.license_attachment = [info.file];
        } else {
          this.formState.license_attachment = info.fileList[1]
            ? [info.fileList[0]]
            : [];
        }
      }
    },
    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
    },
  },
};
</script>

<style></style>
