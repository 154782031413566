<template>
  <div>
    <a-form
      name="certificate_form"
      ref="certificate_form"
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit()"
    >
      <a-row :gutter="[20, 0]">
        <a-col :span="24" v-if="extra.certificate.is_expirable">
          <a-form-item
            ref="expiry_date"
            label="Expiration Date"
            name="expiry_date"
          >
            <a-date-picker
              v-model:value="formState.expiry_date"
              :format="$constants.datepickerFormat"
              :disabled="disableExpirationDate"
              :placeholder="$constants.datePickerPlaceholder"
              :disabled-date="disabledDate"
              :allow-clear="false"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24" v-if="extra.certificate.has_state">
          <a-form-item ref="state" label="State" name="state">
            <a-select
              v-model:value="formState.state"
              placeholder="Select One..."
              optionFilterProp="label"
              showSearch
            >
              <a-select-option
                v-for="state in $constants.states"
                :key="state.name"
                :label="state.name"
                :value="state.name"
              >
                {{ state.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item
            ref="attachment"
            label="Attachment"
            name="attachment"
            :validateStatus="attachmentError.hasError"
            :help="attachmentError.errorMessage"
            @change="
              () =>
                (attachmentError.hasError = '') &&
                (attachmentError.errorMessage = '')
            "
          >
            <a-upload
              :file-list="formState.attachment"
              :before-upload="
                (_, fileList) =>
                  beforeUpload(fileList, extra.certificate.filetype_supported)
              "
              :multiple="extra.certificate.attachment_limit == 1 ? true : false"
              :accept="extra.certificate.filetype_supported"
              :remove="onFileRemove"
            >
              <div>
                <a-button>
                  <UploadOutlined />
                  Upload
                </a-button>
              </div>
            </a-upload>
          </a-form-item>
        </a-col>
      </a-row>
      <side-drawer-buttons name="Create" />
    </a-form>
  </div>
</template>

<script>
import { companyService } from "@/services";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    PlusOutlined,
    UploadOutlined,
  },
  data() {
    return {
      formState: {},
      attachmentError: {
        hasError: "",
        errorMessage: "",
      },
      rules: {
        attachment: [
          {
            required: true,
            type: "any",
            message: "Attachment is required",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("drawer", ["record", "extra"]),
    disableExpirationDate() {
      return [8].includes(this.extra.certificate.id);
    },
  },
  mounted() {
    this.getFormState();
    const certificate = this.extra.certificate;

    if (certificate.is_expirable === 1) {
      this.rules.expiry_date = [
        {
          required: true,
          type: "date",
          message: "expiration date is required!",
        },
      ];
    }
    if (certificate.has_state === 1) {
      this.rules.state = [
        {
          required: true,
          message: "state field is required!",
        },
      ];
    }

    // Flu Certificate ID = 8
    if ([8].includes(certificate.id)) {
      this.setExpirationDateForCertainCertificates();
    }
  },
  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),
    ...mapActions("auth", ["login"]),

    setExpirationDateForCertainCertificates() {
      const currentDate = moment();
      const oct31 = moment().month(9).date(31);

      if (currentDate.isAfter(oct31)) {
        this.formState.expiry_date = oct31.add(1, "year");
      } else {
        this.formState.expiry_date = oct31;
      }
    },

    getFormState() {
      this.formState = {
        expiry_date: "",
        state: undefined,
        attachment: [],
      };
    },
    disabledDate(current) {
      return current && current < moment().startOf("day");
    },
    onFileRemove(file) {
      const items = this.formState.attachment;
      const idx = items.findIndex((v) => v.uid === file.uid);
      if (idx > -1) items.splice(idx, 1);
    },
    beforeUpload(fileList, types) {
      const acceptedTypes = types.split(",");

      this.formState.attachment = fileList.filter((file) => {
        const isCorrectType = acceptedTypes.includes(file.type);
        const isCorrectSize = file.size / 1024 / 1024 < 5;

        if (!isCorrectType) {
          this.attachmentError.hasError = "error";
          this.attachmentError.errorMessage = `File type of ${file.type} is not supported.`;
          return false;
        }
        if (!isCorrectSize) {
          this.attachmentError.hasError = "error";
          this.attachmentError.errorMessage = `File size of ${file.name} is too large. Max: 5MB`;
          // this.$message.error(
          //   `File size of ${file.name} is too large. Max: 5MB`
          // );
          return false;
        }
        return true;
      });
      return false;
    },
    onSubmit() {
      if (
        this.formState.attachment.length == 0 ||
        this.formState.attachment == ""
      ) {
        this.$refs.certificate_form.fields.forEach((v, i) => {
          if (v.fieldName == "attachment") {
            v.validateState = "error";
            v.validateMessage = "Attachment is required";
          }
        });
      } else {
        this.$refs.certificate_form
          .validate()
          .then(() => {
            const data = new FormData();
            const form = this.formState;
            const allowMultiple = !!this.extra.certificate.attachment_limit;

            data.append("certificate_id", this.extra.certificate.id);
            if (this.extra.certificate.is_expirable == 1)
              data.append("expiry_date", form.expiry_date.format("DD-MM-YYYY"));
            if (this.extra.certificate.has_state == 1)
              data.append("state", form.state);

            // if (allowMultiple) {
            form.attachment.forEach((file) => {
              data.append(`attachment[]`, file);
            });
            // } else data.append("attachment[]", form.attachment);

            this.loadingStart();
            companyService
              .store(this.$constants.storeEmployeeCertificate, data, true)
              .then(async (res) => {
                this.loadingStop();
                if (res.success) {
                  this.$message.success(res.message);
                  this.close();
                  this.$emit("callback");
                  await this.login({ isInitStore: false });
                }
              })
              .catch((err) => {
                this.loadingStop();
                this.$refs.certificate_form.fields.forEach((v, i) => {
                  if (err.errors[v.fieldName]) {
                    v.validateState = "error";
                    v.validateMessage = err.errors[v.fieldName]["0"];
                  }
                });
              });
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    },
  },
};
</script>

<style></style>
