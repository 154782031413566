<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      :title="title"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    />

    <a-card size="small" :loading="cardLoader" hoverable>
      <a-form
        name="cell_saver_quality_control"
        ref="cell_saver_quality_control"
        :model="model"
        :rules="rules"
        layout="vertical"
        @finish="handleFinish"
      >
        <a-row :gutter="[20, 0]">
          <a-col :span="12">
            <a-form-item ref="date" label="Date Sample Drawn" name="date">
              <a-date-picker
                :allowClear="false"
                v-model:value="model.date"
                :disabled-date="$comman.disabledFutureDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item ref="mr_number" label="MR Number" name="mr_number">
              <a-input
                v-model:value="model.mr_number"
                placeholder="MR Number"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="asset_inventory_id"
              label="Select cell saver device used"
              name="asset_inventory_id"
            >
              <a-select
                v-model:value="model.asset_inventory_id"
                placeholder="Search device by serial number"
                optionFilterProp="label"
                showSearch
                @search="fetchAssetInventory"
                @change="handleDeviceChange"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>

                <a-select-option
                  v-for="option in assetInventory"
                  :key="option.id"
                  :label="`${option.asset_product?.name} | ${option.model_no} | ${option.serial_no}`"
                  :value="option.id"
                >
                  {{ option.asset_product?.name }} | {{ option.model_no }} |
                  {{ option.serial_no }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-descriptions
          v-if="model.asset_inventory_data"
          title="Asset Inventory Info"
          bordered
          size="small"
          :column="2"
        >
          <a-descriptions-item label="Product Name">
            {{ model.asset_inventory_data.asset_product?.name ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Manufacturer Name">
            {{ model.asset_inventory_data.asset_manufacturer?.name ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Owned By">
            <a-tag
              :color="
                $comman.getValueFromObject(
                  model,
                  'owned_by',
                  $constants.assetInventoryOwnedBy,
                  'color'
                )
              "
            >
              {{
                $comman.getValueFromObject(
                  model,
                  "owned_by",
                  $constants.assetInventoryOwnedBy
                )
              }}
            </a-tag>
          </a-descriptions-item>
          <a-descriptions-item label="Owned Name">
            <template v-if="model.owned_by == 1">
              {{ model.asset_inventory_data.company?.name ?? "N/A" }}
            </template>
            <template v-else>
              {{ model.asset_inventory_data.hospital?.name ?? "N/A" }}
            </template>
          </a-descriptions-item>
          <a-descriptions-item
            v-if="model.asset_inventory_data.asset_inventory_hospital"
            label="Allocated To"
            :span="2"
          >
            {{
              model.asset_inventory_data.asset_inventory_hospital?.hospital
                ?.name ?? "N/A"
            }}
          </a-descriptions-item>
          <a-descriptions-item label="Model No">
            {{ model.asset_inventory_data.model_no ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Serial No">
            {{ model.asset_inventory_data.serial_no ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Asset ID Tag">
            {{ model.asset_inventory_data.asset_id_tag ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Secondary ID Tag">
            {{ model.asset_inventory_data.secondary_id_tag ?? "N/A" }}
          </a-descriptions-item>
          <a-descriptions-item label="Description" :span="2">
            {{ model.asset_inventory_data.description ?? "N/A" }}
          </a-descriptions-item>
        </a-descriptions>

        <a-space direction="vertical" style="width: 100%">
          <a-table
            :columns="columns"
            :rowKey="(record, index) => index"
            :dataSource="model.sources"
            :pagination="false"
            size="small"
            class="ant-table-vertical-align-baseline"
            :scroll="{ x: true, y: 0 }"
          >
            <template #source="{ record, index }">
              <a-form-item
                :name="['sources', index, 'source']"
                :rules="{
                  required: true,
                  message: 'This field is required!',
                  trigger: $constants.ruleTrigger,
                  transform: (value) => (value ? value.trim() : ''),
                }"
              >
                <a-input
                  v-model:value="record.source"
                  placeholder="Enter source here..."
                />
              </a-form-item>
            </template>

            <template #parameter="{ record, index }">
              <a-form-item
                :name="['sources', index, 'parameter']"
                :rules="{
                  required: true,
                  message: 'This field is required!',
                  trigger: $constants.ruleTrigger,
                }"
              >
                <a-select
                  v-model:value="record.parameter"
                  placeholder="Select One..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="option in $constants.cellSaverSourcesParameters"
                    :key="option"
                    :label="option"
                    :value="option"
                  >
                    {{ option }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </template>

            <template #result="{ record, index }">
              <a-form-item
                :name="['sources', index, 'result']"
                :rules="[
                  {
                    required: true,
                    message: 'This field is required!',
                  },
                  {
                    message: 'This field is required!',
                    type: 'number',
                    validator: $validation.decimal,
                    trigger: $constants.ruleTrigger,
                  },
                ]"
              >
                <a-input-number
                  type="number"
                  v-model:value="record.result"
                  placeholder="Enter result here..."
                />
              </a-form-item>
            </template>

            <template #action="{ index }">
              <a-button
                v-if="model.sources.length > 1"
                size="small"
                type="link"
                danger
                @click="handleRowDeleteEvent(index)"
              >
                <i class="ti ti-trash ti-lg"></i>
              </a-button>

              <a-button
                v-if="model.sources.length == index + 1"
                size="small"
                type="link"
                @click="handleRowAddEvent"
              >
                <i class="ti ti-plus ti-lg"></i>
              </a-button>
            </template>
          </a-table>

          <a-row type="flex" justify="center">
            <a-space direction="vertical">
              <vue-signature-pad
                :options="{ onEnd: save }"
                ref="signaturePad"
                :customStyle="$constants.signPadCustomCss"
              />

              <a-row type="flex" justify="center">
                <a-col>
                  <a-typography-paragraph>
                    Employee signature indicates that the information reported
                    is complete and accurate.
                  </a-typography-paragraph>
                </a-col>
              </a-row>
              <a-row v-if="model.signature" justify="center">
                <a-space>
                  <a-button
                    htmlType="button"
                    type="primary"
                    danger
                    ghost
                    @click="clear"
                  >
                    Clear
                  </a-button>
                </a-space>
              </a-row>
            </a-space>
          </a-row>

          <a-button
            type="primary"
            html-type="submit"
            :loading="submitBtnLoader"
          >
            {{ submitBtnText }}
          </a-button>
        </a-space>
      </a-form>
    </a-card>
  </a-layout-content>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import backButton from "../../components/backButton.vue";
import { cellSaverQCRule } from "../../helper/formRules";
import formMixins from "../../mixins/formMixins";
import { commonService } from "../../services";

export default {
  components: { backButton },

  data() {
    return {
      cardLoader: true,
      submitBtnLoader: false,
      fetching: false,
      fetchingTimeOut: null,
      assetInventory: [],
      deviceId: this.$route.query?.device ?? null,
      model: {
        id: this.$route.params.id ?? 0,
        employee_id: 0,
        date: null,
        mr_number: null,
        asset_inventory_id: null,
        asset_product_id: null,
        asset_manufacturer_id: null,
        owned_by: null,
        hospital_id: null,
        company_id: null,
        allocated_to: null,
        asset_inventory_data: null,
        sources: [
          {
            source: null,
            parameter: undefined,
            result: null,
          },
        ],
      },
      columns: [
        {
          title: "Source",
          dataIndex: "source",
          key: "source",
          slots: { customRender: "source" },
        },
        {
          title: "Parameter",
          dataIndex: "parameter",
          key: "parameter",
          slots: { customRender: "parameter" },
        },
        {
          title: "Result",
          dataIndex: "result",
          key: "result",
          slots: { customRender: "result" },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          width: 150,
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      rules: cellSaverQCRule,
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("auth", ["employee"]),

    title() {
      if (this.model.id == 0) {
        return "Add New Cell Saver Quality Control";
      }
      return "Edit Cell Saver Quality Control";
    },

    submitBtnText() {
      if (this.model.id == 0) {
        return "Create";
      }
      return "Update";
    },
  },

  async mounted() {
    await this.fetchDevices();
    if (this.model.id != 0) {
      await this.fetchCellSaverQC();
    }

    if (this.deviceId) {
      await this.fetchData({ id: this.deviceId });
      this.model.asset_inventory_id = parseInt(this.deviceId);
      this.handleDeviceChange(this.deviceId);
    }

    this.cardLoader = false;
    setTimeout(() => {
      this.$refs.signaturePad.fromDataURL(this.model.signature);
    }, 500);
  },

  methods: {
    fetchCellSaverQC() {
      return commonService
        .get(this.$constants.cellSaverQCShowUrl, { id: this.model.id })
        .then(async (res) => {
          if (!res.success) {
            this.$message.error(res.message);
            this.$router.go(-1);
            return;
          }

          let data = res.data;

          this.model = {
            id: this.model.id,
            hospital_id: data.hospital_id,
            date: moment(data.date),
            mr_number: data.mr_number,
            asset_inventory_id: data.asset_inventory_id,
            asset_product_id: data.asset_product_id,
            asset_manufacturer_id: data.asset_manufacturer_id,
            owned_by: data.owned_by,
            hospital_id: data.hospital_id,
            company_id: data.company_id,
            allocated_to: data.allocated_to,
            asset_inventory_data: data.asset_inventory_data,
            sources: data.sources,
            signature: data.signature,
          };

          await this.fetchData({ id: data.asset_inventory_id });
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    fetchAssetInventory(value) {
      this.fetching = true;
      clearTimeout(this.fetchingTimeOut);

      this.fetchingTimeOut = setTimeout(this.fetchData, 500, {
        search: value,
      });
    },

    fetchData(value) {
      return commonService
        .get(this.$constants.assetInventorySearchUrl, {
          ...value,
          status: [1, 2],
          is_cell_saver: true,
        })
        .then((res) => {
          this.assetInventory = res.data;
        })
        .catch(() => (this.assetInventory = []))
        .finally(() => (this.fetching = false));
    },

    fetchDevices() {
      this.fetching = true;
      return commonService
        .get(this.$constants.assetInventorySearchUrl, {
          status: [1, 2],
          is_cell_saver: true,
        })
        .then((res) => {
          this.assetInventory = res.data;
        })
        .catch(() => (this.assetInventory = []))
        .finally(() => (this.fetching = false));
    },

    handleDeviceChange(id) {
      let asset = this.assetInventory.find((item) => item.id == id);
      this.model.asset_product_id = asset.asset_product_id;
      this.model.asset_manufacturer_id = asset.asset_manufacturer_id;
      this.model.company_id = asset.company_id;
      this.model.hospital_id = asset.hospital_id;
      this.model.owned_by = asset.owned_by;
      this.model.allocated_to =
        asset.asset_inventory_hospital?.hospital_id ?? null;
      this.model.asset_inventory_data = asset;
    },

    handleFinish() {
      if (this.model.signature == null) {
        this.$message.error("Signature must be required!");
        return;
      }

      if (this.model.id == 0) {
        this.model.employee_id = this.employee.id;
      }

      this.submitBtnLoader = true;
      commonService
        .store(this.$constants.cellSaverQCStoreUrl, {
          ...this.model,
          date: this.$customDate.ymd(this.model.date),
        })
        .then((res) => {
          if (res.success) {
            this.submitBtnLoader = false;
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
          this.$router.go(-1);
        })
        .catch((err) => {
          this.submitBtnLoader = false;
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },

    handleRowDeleteEvent(index) {
      this.model.sources = this.model.sources.filter((_, i) => index != i);
    },

    handleRowAddEvent() {
      this.model.sources.push({
        source: null,
        parameter: undefined,
        result: null,
      });
    },

    clear() {
      this.model.signature = null;
      this.$refs.signaturePad.clearSignature();
    },

    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.model.signature = data;
      return Promise.resolve();
    },
  },
};
</script>
