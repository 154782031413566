<template>
  <a-layout-content>
    <page-header>
      <template #title> Biomed Service Record </template>

      <template #buttons>
        <router-link
          v-if="$comman.hasRole([1, 4])"
          :to="{ name: 'create-biomed-service-record' }"
        >
          <a-button type="primary">
            <a-space> <i class="ti ti-plus ti-lg"></i> Add New </a-space>
          </a-button>
        </router-link>
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="parentColumns"
        :rowKey="(record) => record.asset_inventory_id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #action="{ record }">
          <a-tooltip title="Download All Record">
            <a-button
              type="link"
              size="small"
              @click="downloadPdfByAssetInventory(record)"
            >
              <i class="ti ti-download ti-lg"></i>
            </a-button>
          </a-tooltip>
        </template>

        <template #expandedRowRender="{ record: rec }">
          <a-table
            :columns="columns"
            :data-source="rec.rows"
            :rowKey="(record) => record.id"
            :pagination="false"
            size="small"
          >
            <template #action="{ record }">
              <a-space :size="1">
                <router-link
                  :to="{
                    name: 'show-biomed-service-record',
                    params: { id: record.id },
                  }"
                >
                  <a-tooltip title="View">
                    <a-button size="small" type="link" class="primary-color">
                      <i class="ti ti-eye ti-lg"></i>
                    </a-button>
                  </a-tooltip>
                </router-link>

                <a-tooltip title="Download">
                  <a-button
                    type="link"
                    size="small"
                    @click="downloadPdf(record)"
                  >
                    <i class="ti ti-download ti-lg"></i>
                  </a-button>
                </a-tooltip>

                <template v-if="record.status == 1">
                  <router-link
                    v-if="$comman.hasRole([1, 4])"
                    :to="{
                      name: 'edit-biomed-service-record',
                      params: { id: record.id },
                    }"
                  >
                    <a-tooltip title="Edit">
                      <a-button type="link" size="small">
                        <i class="ti ti-pencil ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </router-link>

                  <a-popconfirm
                    v-if="$comman.hasRole([1, 4])"
                    title="Are you sure you want to delete this biomed service record?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="
                      handleStatusChangeEvent({ id: record.id, status: '0' })
                    "
                  >
                    <a-tooltip title="Delete">
                      <a-button size="small" type="link" danger>
                        <i class="ti ti-trash ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </a-popconfirm>
                </template>

                <template v-else>
                  <a-popconfirm
                    v-if="$comman.hasRole([1, 4])"
                    title="Are you sure you want to restore this biomed service record?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="
                      handleStatusChangeEvent({ id: record.id, status: '1' })
                    "
                  >
                    <a-tooltip title="Restore">
                      <a-button size="small" type="link" class="pl-sm-0">
                        <i class="ti ti-rotate ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </a-popconfirm>
                </template>
              </a-space>
            </template>
          </a-table>
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import datatableMixins from "../../mixins/datatableMixins";
import { commonService } from "../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.biomedServiceRecordDatatableUrl,
      statusChangeUrl: this.$constants.biomedServiceRecordChangeStatusUrl,
      parentColumns: [
        {
          title: "Device Info",
          dataIndex: "device_info",
          key: "device_info",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.device_info
            ? [this.$route.query?.device_info]
            : undefined,
        },
        {
          title: "Hospital",
          dataIndex: "allocate_hospital",
          key: "allocate_hospital",
        },
        {
          title: "Last Service Date",
          dataIndex: "last_service_date",
          key: "last_service_date",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
      columns: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
        },
        {
          title: "Employee",
          dataIndex: "employee_name",
          key: "employee_name",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Active",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "0",
          counts: 0,
          text: "Inactive",
          status: 0,
          slots: { tab: "customRender" },
        },
      ],
      defaultFilterParams: {
        device_info: this.$route.query?.device_info
          ? [this.$route.query?.device_info]
          : undefined,
      },
    };
  },

  methods: {
    downloadPdf(data) {
      this.datatableLoading = true;
      commonService
        .downloadFile(
          this.$constants.biomedServiceRecordExportPdfUrl,
          { id: data.id },
          `biomed-service-record.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    downloadPdfByAssetInventory(data) {
      this.datatableLoading = true;
      commonService
        .downloadFile(
          this.$constants.biomedServiceByAssetInventoryRecordExportPdfUrl,
          { ids: data.ids },
          `biomed-service-record.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },
  },
};
</script>
