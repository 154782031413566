<template>
  <div>
    <a-layout-content>
      <page-header>
        <template #title> Expenses </template>

        <template #buttons>
          <!--  -->
        </template>
      </page-header>

      <a-spin :spinning="datatableLoading">
        <a-card
          :tabList="tabList"
          :activeTabKey="activeTabKey"
          @tabChange="(key) => (activeTabKey = key)"
          size="small"
        >
          <template #title>
            <a-row :gutter="[16, 0]">
              <a-col :span="6">
                <a-range-picker
                  v-model:value="filters.date_range"
                  :format="$constants.datepickerFormat"
                  :placeholder="[
                    $constants.datePickerPlaceholder,
                    $constants.datePickerPlaceholder,
                  ]"
                />
              </a-col>
              <a-col :span="6">
                <a-input
                  v-model:value="filters.search_text"
                  placeholder="Search hospital or employee here..."
                  allowClear
                />
              </a-col>
              <a-col :span="12">
                <a-space>
                  <a-button type="primary" @click="fetchData()">
                    Search
                  </a-button>
                  <a-button ghost danger @click="reset()">Reset</a-button>
                  <a-button type="primary" @click="handleExportEvent('pdf')">
                    Export PDF
                  </a-button>
                  <a-button type="primary" @click="handleExportEvent('excel')">
                    Export Excel
                  </a-button>
                </a-space>
              </a-col>
            </a-row>
          </template>

          <template #customRender="item">
            {{ item.text }} ({{ item.counts }})
          </template>

          <div class="mb-sm-1" v-if="activeTabKey == 2 && showSettingError">
            <a-alert type="warning">
              <template #message>
                To sync bills on QuickBooks, Vendor account has not been set.
                Please go to Admin > Settings > QuickBooks
              </template>
            </a-alert>
          </div>

          <a-row class="mb-sm-1" justify="space-between">
            <div>
              <div v-if="activeTabKey == 1" style="display: flex">
                <a-select
                  v-model:value="bulkActionValue"
                  placeholder="Select any one"
                  style="width: 150px; margin-right: 20px"
                >
                  <template v-if="activeTabKey == 1">
                    <a-select-option value="2"> Approve </a-select-option>
                    <a-select-option value="3"> Reject </a-select-option>
                  </template>
                </a-select>

                <a-button
                  html-type="button"
                  type="primary"
                  :disabled="
                    this.bulkActionValue == null ||
                    this.selectedRowKeys.length == 0
                  "
                  @click="bulkAction"
                >
                  Submit
                </a-button>
              </div>

              <a-button
                v-if="activeTabKey == 2"
                size="small"
                type="primary"
                ghost
                htmlType="button"
                :disabled="showSettingError || selectedRowKeys.length == 0"
                @click="uploadExpenseOnQuickbooks"
                :loading="uploadExpenseOnQuickbooksLoading"
              >
                <a-space>
                  <i class="ti ti-refresh ti-1x"></i>
                  Upload expenses on QuickBooks
                </a-space>
              </a-button>

              <a-button
                v-if="activeTabKey == 5"
                size="small"
                type="primary"
                ghost
                htmlType="button"
                :disabled="showSettingError"
                @click="updateExpenseFromQuickbooks"
                :loading="updateExpenseFromQuickbooksLoading"
              >
                <a-space>
                  <i class="ti ti-refresh ti-1x"></i>
                  Update expenses from QuickBooks
                </a-space>
              </a-button>
            </div>
          </a-row>

          <a-table
            :row-selection="rowSelection"
            :columns="columns"
            :rowKey="(record, index) => record.id"
            :dataSource="dataSource"
            :pagination="pagination"
            @change="handleTableChange"
            @refresh="fetchData"
            size="small"
            :scroll="scroll"
          >
            <template #filterDropdown="filterDropdown">
              <xFilterInputSearchDropdown
                :filterDropdown="filterDropdown"
                @handleSearch="handleDatatableSearch"
                @handleReset="handleDatatableReset"
              />
            </template>

            <template #filterIcon="filterIcon">
              <xFilterIcon :filterIcon="filterIcon" />
            </template>

            <template #employee_full_name="{ record }">
              <a-row type="flex" justify="start" align="top" :gutter="12">
                <a-col>
                  <a-space :size="3">
                    {{ record.employee.full_name }}
                    <a-tooltip v-if="hasDBA(record.employee)" placement="top">
                      <template #title>
                        DBA: {{ record.employee.dba }}
                      </template>
                      <InfoCircleOutlined style="color: #1890ff" />
                    </a-tooltip>
                  </a-space>
                </a-col>
                <a-col>
                  <a-tooltip v-if="record.is_duplicate_entry" placement="top">
                    <template #title> Possible Duplicate Entry </template>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="icon icon-tabler icons-tabler-filled icon-tabler-info-triangle"
                      style="color: #fa8c16"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M12 1.67c.955 0 1.845 .467 2.39 1.247l.105 .16l8.114 13.548a2.914 2.914 0 0 1 -2.307 4.363l-.195 .008h-16.225a2.914 2.914 0 0 1 -2.582 -4.2l.099 -.185l8.11 -13.538a2.914 2.914 0 0 1 2.491 -1.403zm0 9.33h-1l-.117 .007a1 1 0 0 0 0 1.986l.117 .007v3l.007 .117a1 1 0 0 0 .876 .876l.117 .007h1l.117 -.007a1 1 0 0 0 .876 -.876l.007 -.117l-.007 -.117a1 1 0 0 0 -.764 -.857l-.112 -.02l-.117 -.006v-3l-.007 -.117a1 1 0 0 0 -.876 -.876l-.117 -.007zm.01 -3l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z"
                      />
                    </svg>
                  </a-tooltip>
                </a-col>
              </a-row>
            </template>

            <template #amount="{ record }">
              {{ $comman.withCurrency(record.amount) }}
              <template v-if="record.quickbooks_bill_id">
                (Bill Id: {{ record.quickbooks_bill_id }})
              </template>
            </template>

            <template #action="{ record }">
              <a-space :size="1">
                <a-tooltip title="View">
                  <a-button
                    type="link"
                    size="small"
                    class="primary-color"
                    @click="handleShowExpenseEvent(record)"
                  >
                    <i class="ti ti-eye ti-lg"></i>
                  </a-button>
                </a-tooltip>

                <template v-if="record.status == 1">
                  <router-link
                    :to="{
                      name: 'admin-update-expense',
                      params: { id: record.id },
                    }"
                  >
                    <a-tooltip title="Edit">
                      <a-button type="link" size="small">
                        <i class="ti ti-pencil ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </router-link>
                </template>

                <template
                  v-if="record.status == 2 && record.quickbooks_bill_id == null"
                >
                  <!-- <a-tooltip title="Add Quickbooks Bill ID">
                    <a-button
                      type="link"
                      size="small"
                      @click="openStoreQuickbooksBillIdForm(record)"
                    >
                      <i class="ti ti-plus ti-lg"></i>
                    </a-button>
                  </a-tooltip>-->

                  <a-tooltip
                    v-if="record.status == 2"
                    title="Edit Quickbooks Class ID"
                  >
                    <a-button
                      type="link"
                      size="small"
                      @click="openStoreQuickbooksClassIdForm(record)"
                    >
                      <i class="ti ti-edit ti-lg"></i>
                    </a-button>
                  </a-tooltip>

                  <a-tooltip title="Upload on Quickbooks">
                    <a-button
                      type="link"
                      size="small"
                      @click="uploadExpenses([record.id])"
                    >
                      <i class="ti ti-upload ti-lg"></i>
                    </a-button>
                  </a-tooltip>
                </template>
              </a-space>
            </template>
          </a-table>
        </a-card>
      </a-spin>
    </a-layout-content>
  </div>
</template>

<script>
import { h } from "vue";
import { mapActions } from "vuex";
import { commonService } from "../../../services";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import xFilterInputSearchDropdown from "@/components/table/filterInputSearchDropdown.vue";
import xFilterIcon from "@/components/table/filterIcon.vue";

export default {
  data() {
    return {
      filters: {
        date_range: [],
        search_text: "",
        page:
          this.$route.query && this.$route.query.page
            ? Number(this.$route?.query?.page)
            : 1,
        pageSize:
          this.$route.query && this.$route.query.pageSize
            ? Number(this.$route?.query?.pageSize)
            : 10,
        sortField: this.$route.query?.sortField ?? "start_date",
        sortOrder: this.$route.query?.sortOrder ?? "descend",
      },
      columns: [
        {
          title: "Employee",
          dataIndex: "employee_name",
          key: "employee_name",
          slots: {
            customRender: "employee_full_name",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Expense Type",
          dataIndex: "expense_type",
          key: "expense_type",
          customRender: ({ record }) => record.expense_type?.name,
        },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          sorter: true,
          slots: {
            customRender: "amount",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultSortOrder:
            this.$route.query.sortField == "amount"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Expense Start Date",
          dataIndex: "start_date",
          key: "start_date",
          sorter: true,
          defaultSortOrder: this.$route.query.sortField
            ? this.$route.query.sortField == "start_date"
              ? this.$route.query.sortOrder
              : undefined
            : "descend",
          customRender: ({ text }) => this.$customDate.ll(text),
        },
        {
          title: "Expense End Date",
          dataIndex: "end_date",
          key: "end_date",
          sorter: true,
          defaultSortOrder:
            this.$route.query.sortField == "end_date"
              ? this.$route.query.sortOrder
              : undefined,
          customRender: ({ text }) => this.$customDate.ll(text),
        },
        {
          title: "Created At",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          customRender: ({ text }) => this.$customDate.ll(text),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.expenseStatusesForAdmin.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      datatableLoading: true,
      dataSource: [],
      scroll: { x: true, y: 0 },
      pagination: {
        current:
          this.$route.query && this.$route.query.page
            ? Number(this.$route?.query?.page)
            : 1,
        size: "normal",
        pageSize:
          this.$route.query && this.$route.query.pageSize
            ? Number(this.$route?.query?.pageSize)
            : 10,
        total: 0,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showSizeChanger: true,
      },

      selectedRowKeys: [],
      bulkActionValue: null,

      uploadExpenseOnQuickbooksLoading: false,
      updateExpenseFromQuickbooksLoading: false,
      settings: [],
      expenseTypes: [],
    };
  },

  components: {
    xFilterIcon,
    xFilterInputSearchDropdown,
    InfoCircleOutlined,
  },

  mounted() {
    Promise.all([
      this.fetchExpenseTypes(),
      this.fetchData(),
      this.fetchSetting(),
    ]);
  },

  computed: {
    rowSelection() {
      return ["1", "2"].includes(this.activeTabKey)
        ? {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          }
        : null;
    },

    showSettingError() {
      return !Object.keys(this.settings).includes("vendor_bill_account");
    },
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    fetchExpenseTypes() {
      commonService.get(this.$constants.getExpenseTypesUrl).then((res) => {
        this.expenseTypes = res.data;
        this.columns[2].filters = this.expenseTypes.map((item) => {
          return {
            text: item.name,
            value: item.id,
          };
        });
      });
    },

    handleDatatableSearch({ selectedKeys, confirm, dataIndex }) {
      confirm();
    },

    hasDBA(employee) {
      return employee.employment_type === 2 && employee.dba;
    },

    fetchData() {
      let data = {
        ...this.filters,
        status: this.activeTabKey,
        search_text:
          this.filters.search_text.length > 0
            ? this.filters.search_text
            : undefined,
        date_range:
          this.filters.date_range.length > 0
            ? [
                this.$customDate.ymd(this.filters.date_range[0]),
                this.$customDate.ymd(this.filters.date_range[1]),
              ]
            : undefined,
      };
      this.$router.replace({ query: data });
      this.datatableLoading = true;
      commonService
        .get(this.$constants.adminExpenseDatatableUrl, data)
        .then((response) => {
          let res = response.data;
          this.pagination = { ...this.pagination, total: res.data.total };
          this.tabItemCounts(res.counts);
          this.dataSource = res.data.data;
        })
        .catch((err) => this.$message.error(err))
        .finally(() => (this.datatableLoading = false));
    },

    tabItemCounts(counts) {
      if (this.tabList) {
        this.tabList.forEach((v, i) => {
          v.counts = counts[v.status] ?? 0;
        });
      }
    },

    handleTableChange(pagination, filtr, sorter) {
      this.pagination = pagination;

      const cleanedFilters = Object.keys(filtr).reduce((acc, key) => {
        if (!filtr[key].length) {
          delete this.filters[key];
        }

        if (filtr[key] !== undefined && filtr[key].length > 0) {
          acc[key] = filtr[key];
        }
        return acc;
      }, {});

      this.filters = {
        ...this.filters,
        page: pagination.current,
        pageSize: pagination.pageSize,
        sortField: sorter.order ? sorter.field : undefined,
        sortOrder: sorter.order,
        ...cleanedFilters,
      };
      this.fetchData();
    },

    handleDatatableReset({ clearFilters }) {
      clearFilters();
      delete this.filters.employee_name;
      delete this.filters.hospital_name;
      delete this.filters.expense_type;
      delete this.filters.amount;
    },

    reset() {
      this.filters.date_range = [];
      this.filters.search_text = "";
      this.filters.page = 1;
      this.fetchData();
    },

    handleShowExpenseEvent(record) {
      this.open({
        title: "Show Expense Details",
        path: "admin.expense.show",
        callback: this.fetchData,
        record: record,
      });
    },

    openStoreQuickbooksBillIdForm(record) {
      this.open({
        title: "Add Quickbooks Bill Id",
        path: "admin.expense.storeQuickbooksBillId",
        callback: this.fetchData,
        record: record,
      });
    },

    openStoreQuickbooksClassIdForm(record) {
      this.open({
        title: "Edit Quickbooks Class Id",
        path: "admin.expense.editQuickbooksClassId",
        callback: this.fetchData,
        record: record,
      });
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    handleExportEvent(type) {
      let url = this.$constants.adminExpenseExportPdfUrl;
      let fileName = "Employee Time Tracking.pdf";
      if (type == "excel") {
        url = this.$constants.adminExpenseExportExcelUrl;
        fileName = "Employee Time Tracking.xlsx";
      }

      var params = {
        search_text:
          this.filters.search_text.length > 0
            ? this.filters.search_text
            : undefined,
        date_range:
          this.filters.date_range.length > 0
            ? [
                this.$customDate.ymd(this.filters.date_range[0]),
                this.$customDate.ymd(this.filters.date_range[1]),
              ]
            : undefined,
        status: this.activeTabKey,
      };

      if (this.filters.employee_name) {
        params.employee_name = this.filters.employee_name;
      }
      if (this.filters.hospital_name) {
        params.hospital_name = this.filters.hospital_name;
      }
      if (this.filters.expense_type) {
        params.expense_type = this.filters.expense_type;
      }
      if (this.filters.amount) {
        params.amount = this.filters.amount;
      }

      this.datatableLoading = true;
      commonService
        .downloadFile(url, params, fileName)
        .then((res) => {
          this.$message.success(res);
          this.datatableLoading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.datatableLoading = false;
        });
    },

    bulkAction() {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.adminExpenseBulkActionsUrl, {
          ids: this.selectedRowKeys,
          status: this.bulkActionValue,
        })
        .then((res) => {
          this.datatableLoading = false;
          if (res.success) {
            this.selectedRowKeys = [];
            this.bulkActionValue = null;
            this.$message.success(res.message);
            this.fetchData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    uploadExpenseOnQuickbooks() {
      this.uploadExpenses(this.selectedRowKeys);
    },

    uploadExpenses(ids) {
      this.uploadExpenseOnQuickbooksLoading = true;
      this.datatableLoading = true;

      commonService
        .store(this.$constants.uploadExpensesOnQuickbooksUrl, { ids })
        .then((res) => {
          if (res.count || res.count == 0) {
            this.$notification.success({
              message: res.message,
              duration: 10,
            });
          }
          if (res.errors != "") {
            setTimeout(() => {
              this.$notification.error({
                message: "Errors",
                description: h("div", {
                  innerHTML: res.errors,
                }),
                duration: 10,
              });
            }, 1000);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.uploadExpenseOnQuickbooksLoading = false;
          this.datatableLoading = false;
          this.fetchData();
        });
    },

    updateExpenseFromQuickbooks() {
      this.updateExpenseFromQuickbooksLoading = true;

      commonService
        .store(this.$constants.updateExpensesFromQuickbooksUrl)
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.updateExpenseFromQuickbooksLoading = false;
          this.fetchData();
        });
    },

    fetchSetting() {
      this.cardLoading = true;

      commonService
        .store(this.$constants.getSettingUrl)
        .then((res) => {
          if (res.success) {
            this.settings = res.data;
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.cardLoading = false;
        });
    },
  },

  watch: {
    activeTabKey() {
      this.selectedRowKeys = [];
      this.pagination = { ...this.pagination, current: 1 };
      this.filters.page = 1;
      this.fetchData();
    },
  },
};
</script>
