<template>
  <a-form
    :model="formState"
    :rules="rules"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[20, 0]">
      <a-col :xs="24" :lg="24">
        <a-form-item label="Product" name="product_id">
          <a-select
            v-model:value="formState.product_id"
            placeholder="Select product"
            optionFilterProp="label"
            :showSearch="true"
            width="100%"
          >
            <a-select-option
              v-for="option in products"
              :key="option.id"
              :label="`${option.manufacturer.name} | ${option.name} | ${option.sku}`"
              :value="option.id"
            >
              {{ option.manufacturer.name }} | {{ option.name }} |
              {{ option.sku }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item label="Original Price Per Unit">
          <a-input-number
            :value="original_price"
            :step="0.01"
            :formatter="$comman.currencyformatter"
            :parser="$comman.currencyParser"
            disabled
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item label="Hospital Price" name="price">
          <a-input-number
            v-model:value="formState.price"
            placeholder="Enter here..."
            :step="0.01"
            :formatter="$comman.currencyformatter"
            :parser="$comman.currencyParser"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item label="Min. Par Level" name="minimum_par_level_qty">
          <a-input-number
            v-model:value="formState.minimum_par_level_qty"
            placeholder="Enter here..."
            :step="1"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item label="Replenish Qty (As Per UOM)" name="replenish_uom">
          <a-input-number
            v-model:value="formState.replenish_uom"
            placeholder="Enter here..."
            :step="1"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item label="Billing Code" name="billing_code">
          <a-input
            v-model:value="formState.billing_code"
            placeholder="Enter here..."
          />
        </a-form-item>
      </a-col>
    </a-row>

    <side-drawer-buttons :name="submitButtonText" />
  </a-form>
</template>

<script>
import { companyService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";

export default {
  components: {
    PlusOutlined,
  },

  data() {
    return {
      formState: {},
      original_price: null,
      rules: {
        product_id: [
          {
            required: true,
            message: "This field is required!",
            type: "number",
          },
        ],
        price: [
          {
            validator: this.priceValidator,
            type: "number",
          },
        ],
        minimum_par_level_qty: [
          {
            required: true,
            message: "This field is required!",
            type: "integer",
            min: 0,
          },
        ],
        replenish_uom: [
          {
            required: true,
            message: "This field is required!",
            type: "integer",
            min: 0,
          },
        ],
      },
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    products() {
      return this.extra.products;
    },

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        hospital_id: this.record.hospital_id,
        id: this.record.id ?? null,
        product_id: this.record.product_id ?? undefined,
        price: this.record.price ? Number(this.record.price) : "",
        minimum_par_level_qty: this.record.minimum_par_level_qty ?? null,
        replenish_uom: this.record.replenish_uom ?? null,
        billing_code: this.record.billing_code ? this.record.billing_code : "",
      };
    },

    onSubmit() {
      this.loadingStart();

      companyService
        .store(this.$constants.hospitalProductPriceStoreUrl, this.formState)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },

    priceValidator(_, value) {
      if (["", null].includes(value)) {
        return Promise.reject("This field is required!");
      }

      const reg = /^\d+(\.\d{1,2})*$/;
      if (value && !reg.test(Math.abs(value))) {
        return Promise.reject("Price have max update 2 decimal points!");
      }

      if (this.$comman.isLessThen10Percent(this.original_price, value)) {
        return Promise.reject("Product margin should be 10% minimum!");
      }

      return Promise.resolve();
    },
  },

  watch: {
    "formState.product_id": function (val) {
      if (val) {
        let product = this.products.find((item) => item.id == val);
        if (product) {
          this.original_price = Number(
            product.price / (product.uom?.no_of_items ?? 1)
          ).toFixed(2);
        }
      }
    },
  },
};
</script>
