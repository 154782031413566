<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      :title="title"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    />

    <a-card size="small" :loading="cardLoader" hoverable>
      <a-form
        name="incident_form"
        ref="incident_form"
        :model="model"
        :rules="rules"
        layout="vertical"
        @finish="handleFinish"
      >
        <a-row :gutter="[20, 0]">
          <!-- <a-col :span="12">
            <a-form-item
              ref="report_number"
              label="Report Number"
              name="report_number"
            >
              <a-input
                v-model:value="model.report_number"
                placeholder="Enter report number here..."
              />
            </a-form-item>
          </a-col> -->

          <a-col :span="12">
            <a-form-item
              ref="report_date"
              label="Date of Report"
              name="report_date"
              :validateStatus="validate('report_date', 'status')"
              :help="validate('report_date', 'msg')"
            >
              <a-date-picker
                :allowClear="false"
                v-model:value="model.report_date"
                :placeholder="$constants.datePickerPlaceholder"
                :format="$constants.datepickerFormat"
                :disabledDate="$comman.disabledFutureDate"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="position"
              label="Position of Reporting Person"
              name="position"
              :validateStatus="validate('position', 'status')"
              :help="validate('position', 'msg')"
            >
              <a-input
                v-model:value="model.position"
                placeholder="Enter position here..."
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="hospital_id"
              label="Hospital Name"
              name="hospital_id"
              :validateStatus="validate('hospital_id', 'status')"
              :help="validate('hospital_id', 'msg')"
            >
              <a-select
                v-model:value="model.hospital_id"
                placeholder="Select hospital"
                show-search
                optionFilterProp="label"
              >
                <a-select-option
                  v-for="hospital in hospitals"
                  :key="hospital.id"
                  :label="hospital.name"
                  :value="hospital.id"
                >
                  {{ hospital.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="incident_date"
              label="Date of Incident"
              name="incident_date"
              :validateStatus="validate('incident_date', 'status')"
              :help="validate('incident_date', 'msg')"
            >
              <a-date-picker
                :allowClear="false"
                v-model:value="model.incident_date"
                :placeholder="$constants.datePickerPlaceholder"
                :format="$constants.datepickerFormat"
                :disabledDate="$comman.disabledFutureDate"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="device_type"
              label="If a device or product was involved, type of device"
              name="device_type"
            >
              <a-select
                v-model:value="model.device_type"
                placeholder="Select device"
                show-search
              >
                <a-select-option
                  v-for="device in deviceTypes"
                  :key="device"
                  :value="device"
                >
                  {{ device }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item ref="model" label="Model" name="model">
              <a-input
                v-model:value="model.model"
                placeholder="Enter model here..."
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="manufacturer"
              label="Manufacturer"
              name="manufacturer"
            >
              <a-input
                v-model:value="model.manufacturer"
                placeholder="Enter manufacturer here..."
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="reference_number"
              label="Reference Number"
              name="reference_number"
            >
              <a-input
                v-model:value="model.reference_number"
                placeholder="Enter reference number here..."
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="batch_number"
              label="Lot or Batch Number"
              name="batch_number"
            >
              <a-input
                v-model:value="model.batch_number"
                placeholder="Enter lot/batch number here..."
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="manufactured_date"
              label="Date Manufactured"
              name="manufactured_date"
            >
              <a-date-picker
                :allowClear="false"
                v-model:value="model.manufactured_date"
                :placeholder="$constants.datePickerPlaceholder"
                :format="$constants.datepickerFormat"
                :disabledDate="$comman.disabledFutureDate"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="expiration_date"
              label="Expiration Date"
              name="expiration_date"
            >
              <a-date-picker
                :allowClear="false"
                v-model:value="model.expiration_date"
                :placeholder="$constants.datePickerPlaceholder"
                :format="$constants.datepickerFormat"
                :disabledDate="$comman.disabledPastDate"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="device_location"
              label="Current location of the device"
              name="device_location"
            >
              <a-input
                v-model:value="model.device_location"
                placeholder="Enter device location here..."
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="is_contact"
              label="Has the manufacturer/supplier been contacted?"
              name="is_contact"
            >
              <a-radio-group v-model:value="model.is_contact">
                <a-radio :value="1">Yes</a-radio>
                <a-radio :value="0">No</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              ref="result_injury"
              label="Any resulting injury?"
              name="result_injury"
            >
              <a-radio-group v-model:value="model.result_injury">
                <template
                  v-for="injury in $constants.incidentInjuries"
                  :key="injury"
                >
                  <a-radio :value="injury">{{ injury }}</a-radio>
                </template>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
              ref="incident_detail"
              label="Details of incident/Nature of defect"
              name="incident_detail"
            >
              <a-textarea
                v-model:value="model.incident_detail"
                placeholder="Enter incident detail here..."
                :auto-size="{ minRows: 3, maxRows: 6 }"
              />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
              ref="injury_detail"
              label="Details of injury(to patient/healthcare professional)"
              name="injury_detail"
            >
              <a-textarea
                v-model:value="model.injury_detail"
                placeholder="Enter injury detail here..."
                :auto-size="{ minRows: 3, maxRows: 6 }"
              />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
              ref="action_detail"
              label="Action Taken (includes any action by patient, healthcare professional, or by the manufacturer or suppier)"
              name="action_detail"
            >
              <a-textarea
                v-model:value="model.action_detail"
                placeholder="Enter injury detail here..."
                :auto-size="{ minRows: 3, maxRows: 6 }"
              />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-space
              class="mt-sm-2"
              align="center"
              direction="vertical"
              style="width: 100%"
            >
              <vue-signature-pad
                :options="{ onEnd: saveSignature }"
                ref="signaturePad"
                :customStyle="$constants.signPadCustomCss"
              />

              <a-typography-paragraph style="width: 600px; text-align: center">
                By signing this, I certify all information is true and correct
                to the best of my knowledge.
              </a-typography-paragraph>
            </a-space>
          </a-col>
        </a-row>

        <a-space>
          <a-button
            type="primary"
            html-type="submit"
            :loading="submitBtnLoader"
          >
            {{ submitBtnText }}
          </a-button>

          <a-button
            v-if="model.signature"
            htmlType="button"
            type="primary"
            danger
            ghost
            @click="clearSignature"
          >
            Clear Signature
          </a-button>
        </a-space>
      </a-form>
    </a-card>
  </a-layout-content>
</template>

<script>
import { InboxOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapGetters } from "vuex";
import backButton from "../../components/backButton.vue";
import { incidentRule } from "../../helper/formRules";
import formMixins from "../../mixins/formMixins";
import { commonService } from "../../services";

export default {
  components: { backButton, InboxOutlined },

  data() {
    return {
      cardLoader: false,
      submitBtnLoader: false,
      spinning: false,
      hospitals: [],
      deviceTypes: this.$constants.incidentDeviceTypes,
      model: {
        id: this.$route.params.id ?? 0,
        employee_id: null,
        hospital_id: null,
        // report_number: null,
        report_date: null,
        position: null,
        incident_date: null,
        model: null,
        manufacturer: null,
        reference_number: null,
        batch_number: null,
        manufactured_date: null,
        expiration_date: null,
        device_location: null,
        is_contact: 0,
        result_injury: "None",
        incident_detail: null,
        injury_detail: null,
        action_detail: null,
        signature: false,
      },
      rules: incidentRule,
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("auth", ["employee"]),

    title() {
      if (this.model.id == 0) {
        return "Add New Incident";
      }
      return "Edit Incident";
    },

    submitBtnText() {
      if (this.model.id == 0) {
        return "Create";
      }
      return "Update";
    },
  },

  mounted() {
    if (this.model.id != 0) {
      this.fetchIncident();
    }

    this.fetchHospitals();
    setTimeout(() => {
      this.$refs.signaturePad.fromDataURL(this.model.signature);
    }, 500);
  },

  methods: {
    fetchIncident() {
      this.cardLoader = true;
      commonService
        .get(this.$constants.incidentShowUrl, { id: this.model.id })
        .then((res) => {
          if (!res.success) {
            this.$message.error(res.message);
            this.$router.go(-1);
            return;
          }

          let data = res.data;

          this.model = {
            ...data,
            report_date: moment(data.report_date),
            incident_date: moment(data.incident_date),
            manufactured_date: data.manufactured_date
              ? moment(data.manufactured_date)
              : null,
            expiration_date: data.expiration_date
              ? moment(data.expiration_date)
              : null,
          };

          this.cardLoader = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    fetchHospitals() {
      commonService.get(this.$constants.getHospitalsUrl).then((res) => {
        this.hospitals = [
          {
            id: 0,
            name: "Not Applicable",
          },
          ...res.data,
        ];
      });
    },

    clearSignature() {
      this.model.signature = null;
      this.$refs.signaturePad.clearSignature();
    },

    saveSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.model.signature = data;
      return Promise.resolve();
    },

    handleFinish() {
      if (this.model.signature == null) {
        this.$message.error("Signature must be required!");
        return;
      }

      this.submitBtnLoader = true;
      let val = {
        ...this.model,
        employee_id: this.employee.id,
      };

      commonService
        .store(this.$constants.incidentStoreUrl, val)
        .then((res) => {
          if (res.success) {
            this.submitBtnLoader = false;
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
          this.$router.go(-1);
        })
        .catch((err) => {
          this.submitBtnLoader = false;
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },
  },
};
</script>
