<template>
  <div>
    <a-form
      :model="formState"
      :rules="rules"
      ref="formW9"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-alert
        type="warning"
        show-icon
        v-if="!hasBasicInfoFilled"
        class="mb-sm-1"
      >
        <template #description>
          Please fill out
          <a-typography-link @click="goToBasicInfoTab" size="small">
            Basic Details
          </a-typography-link>
          before proceeding with this form.
        </template>
      </a-alert>

      <a-alert
        v-if="oldData"
        class="mb-sm-1"
        type="info"
        description="We have succesfully received Form W-9 submission of yours. Please do not change any information unless you receive any notification from Employer."
      />

      <a-row :gutter="[20, 10]">
        <a-col :xs="24" :sm="12">
          <a-form-item
            label="Name of entity/individual"
            name="topmostSubform[0].Page1[0].f1_01[0]"
          >
            <a-input
              v-model:value="formState['topmostSubform[0].Page1[0].f1_01[0]']"
              placeholder="Enter here..."
              @change="resetErrors('topmostSubform[0].Page1[0].f1_01[0]')"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :sm="12">
          <a-form-item
            label="Business name/disregarded entity name, if different from name of entity/individual"
            name="topmostSubform[0].Page1[0].f1_02[0]"
          >
            <a-input
              v-model:value="formState['topmostSubform[0].Page1[0].f1_02[0]']"
              placeholder="Enter here..."
              @change="resetErrors('topmostSubform[0].Page1[0].f1_02[0]')"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-alert
            type="info"
            size="small"
            message="Note"
            class="mb-sm-2"
            description="Check the “LLC” box below and, in the entry space, enter the appropriate code (C, S, or P) for the tax
classification of the LLC, unless it is a disregarded entity. A disregarded entity should instead check the appropriate
box for the tax classification of its owner."
          />
        </a-col>

        <a-col :xs="24" :lg="12">
          <a-form-item
            label="Check the appropriate box for federal tax classification of the entity/individual whose name is entered"
            name="federal_tax_classification"
          >
            <a-row>
              <a-col :span="1">
                <a-radio-group
                  v-model:value="formState.federal_tax_classification"
                  :options="$constants.federalTaxClassification"
                  size="large"
                />
              </a-col>
            </a-row>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item
            v-if="
              formState.federal_tax_classification ===
              'topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[5]'
            "
            label="Select the tax classification"
            name="federal_tax_classification"
          >
            <a-select
              v-model:value="formState.tax_classification"
              placeholder="Select tax classification"
              :options="taxClassificationSelect"
            />
          </a-form-item>

          <template
            v-if="
              formState.federal_tax_classification ===
              'topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[6]'
            "
          >
            <a-alert
              type="warning"
              size="small"
              class="mb-sm-1"
              description="Enter your name as shown on required U.S. federal tax documents on line 1.
              This name should match the name shown on the charter or other
              legal document creating the entity. Enter any business, trade, or DBA name on line 2."
            />

            <a-form-item label="Other" name="other">
              <a-input
                v-model:value="formState.other"
                placeholder="Enter here..."
              />
            </a-form-item>
          </template>
        </a-col>

        <a-col :span="24" v-if="showForeignBeneficiaries">
          <a-form-item label="" name="has_foreign_beneficiaries">
            <a-checkbox v-model:checked="formState.has_foreign_beneficiaries">
              Are you are providing this form to a partnership, trust, or estate
              in which you have an ownership interest? Check this box if you
              have any foreign partners, owners, or beneficiaries
            </a-checkbox>
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-card
            size="small"
            title="Exemptions (Applies to accounts maintained outside the United States.)"
          >
            <a-row :gutter="[20, 20]" style="padding: 0 12px">
              <a-col :xs="24" :sm="12">
                <a-form-item
                  label="Exempt payee code (if any)"
                  name="topmostSubform[0].Page1[0].f1_05[0]"
                >
                  <a-input
                    v-model:value="
                      formState['topmostSubform[0].Page1[0].f1_05[0]']
                    "
                    placeholder="Enter here..."
                    @change="resetErrors('topmostSubform[0].Page1[0].f1_05[0]')"
                  />
                </a-form-item>
              </a-col>

              <a-col :xs="24" :sm="12">
                <a-form-item
                  label="Exemption from Foreign Account Tax Compliance Act (FATCA) reporting code (if any)"
                  name="topmostSubform[0].Page1[0].f1_06[0]"
                >
                  <a-input
                    v-model:value="
                      formState['topmostSubform[0].Page1[0].f1_06[0]']
                    "
                    placeholder="Enter here..."
                    @change="resetErrors('topmostSubform[0].Page1[0].f1_06[0]')"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </a-card>
        </a-col>

        <a-col :span="24">
          <a-form-item
            label="Requester's Name and Address"
            name="topmostSubform[0].Page1[0].f1_09[0]"
          >
            <a-input
              v-model:value="formState['topmostSubform[0].Page1[0].f1_09[0]']"
              placeholder="Enter here..."
            />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item
            label="Address (number, street, and apt. or suite no.)"
            name="topmostSubform[0].Page1[0].Address_ReadOrder[0].f1_07[0]"
          >
            <a-input
              v-model:value="
                formState[
                  'topmostSubform[0].Page1[0].Address_ReadOrder[0].f1_07[0]'
                ]
              "
              placeholder="Enter here..."
              @change="
                resetErrors(
                  'topmostSubform[0].Page1[0].Address_ReadOrder[0].f1_07[0]'
                )
              "
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :sm="12">
          <a-form-item
            label="City, state, and ZIP code"
            name="topmostSubform[0].Page1[0].Address_ReadOrder[0].f1_08[0]"
          >
            <a-input
              v-model:value="
                formState[
                  'topmostSubform[0].Page1[0].Address_ReadOrder[0].f1_08[0]'
                ]
              "
              placeholder="Enter here..."
              @change="
                resetErrors(
                  'topmostSubform[0].Page1[0].Address_ReadOrder[0].f1_08[0]'
                )
              "
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :sm="12">
          <a-form-item
            label="List account number(s) here"
            name="topmostSubform[0].Page1[0].f1_10[0]"
          >
            <a-input
              v-model:value="formState['topmostSubform[0].Page1[0].f1_10[0]']"
              placeholder="Enter here..."
              @change="resetErrors('topmostSubform[0].Page1[0].f1_10[0]')"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :sm="12">
          <a-form-item label="Social Security Number" name="ssn">
            <a-input
              v-model:value="formState['ssn']"
              placeholder="Enter social security number here"
              v-maska="'###-##-####'"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :sm="12">
          <a-form-item label="Employer Identification Number" name="ein">
            <a-input
              v-model:value="formState['ein']"
              placeholder="Enter your employer identification no. here"
              v-maska="'(##-#######)'"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row type="flex" justify="center">
        <a-space direction="vertical">
          <vue-signature-pad
            :options="{ onEnd: save }"
            ref="signaturePad"
            :customStyle="{
              ...$constants.signPadCustomCss,
              height: '80px',
            }"
          />

          <a-row type="flex" justify="center">
            <a-col>
              <a-typography-paragraph>
                Employee signature indicates that the information reported is
                complete and accurate.
              </a-typography-paragraph>
            </a-col>
          </a-row>
          <a-row v-if="formState.signature" justify="center">
            <a-space>
              <a-button
                htmlType="button"
                type="primary"
                danger
                ghost
                @click="clear"
                class="mb-sm-1"
              >
                Clear
              </a-button>
            </a-space>
          </a-row>
        </a-space>
      </a-row>

      <a-alert
        v-if="oldData"
        class="mb-sm-1"
        type="info"
        description="We have succesfully received Form W-9 submission of yours. Please do not change any information unless you receive any notification from Employer."
      />

      <a-alert
        type="warning"
        show-icon
        v-if="!hasBasicInfoFilled"
        class="mb-sm-1"
      >
        <template #description>
          Please fill out
          <a-typography-link @click="goToBasicInfoTab" size="small">
            Basic Details
          </a-typography-link>
          before proceeding with this form.
        </template>
      </a-alert>

      <a-space>
        <a-button :loading="pdfLoading" @click="previewPDF"> Preview</a-button>

        <a-button
          type="primary"
          html-type="submit"
          :loading="loading"
          :disabled="!hasBasicInfoFilled"
        >
          Submit
        </a-button>
      </a-space>
    </a-form>
  </div>
</template>

<script>
import { commonService, companyService } from "@/services";
import { CheckOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../mixins/formMixins";

export default {
  components: {
    CheckOutlined,
  },
  mixins: [formMixins],
  emits: ["changeTab"],
  data() {
    return {
      loading: false,
      moment,
      pdfLoading: false,
      formData: [],
      oldData: null,
      formState: {
        federal_tax_classification: null,
        tax_classification: null,
        signature: null,
      },
      taxClassificationSelect: [
        {
          label: "Corporation",
          value: "C",
        },
        {
          label: "S Corporation",
          value: "S",
        },
        {
          label: "Partnership",
          value: "P",
        },
      ],
    };
  },

  mounted() {
    this.getFormData();
  },

  computed: {
    ...mapGetters("auth", ["employee"]),
    hasBasicInfoFilled() {
      const employee = this.employee;

      const socialSecurity = employee.social_security_no;
      const license = employee.license_no;

      return !!socialSecurity && !!license;
    },
    showForeignBeneficiaries() {
      const hasCheckedPartnershipOrTrust = [
        "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[3]",
        "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[4]",
      ].includes(this.formState.federal_tax_classification);
      const hasLLCAndPartnership =
        this.formState.federal_tax_classification ===
          "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[5]" &&
        this.formState.tax_classification === "P";

      return hasCheckedPartnershipOrTrust || hasLLCAndPartnership;
    },
    rules() {
      return {
        federal_tax_classification: [
          {
            required: true,
            message: "This field is required",
            trigger: "change",
          },
        ],
        other: [
          {
            required: true,
            message: "This field is required",
            trigger: "change",
          },
        ],
        ein: [
          {
            required: !this.formState["ssn"],
            message: "This field is required",
            trigger: "change",
          },
        ],
        ssn: [
          {
            required: !this.formState["ein"],
            message: "This field is required",
            trigger: "change",
          },
        ],
        "topmostSubform[0].Page1[0].f1_01[0]": [
          {
            required: true,
            message: "This field is required",
            trigger: "change",
          },
        ],
        "topmostSubform[0].Page1[0].f1_02[0]": [
          {
            // required: true,
            message: "This field is required",
            trigger: "change",
          },
        ],
        "topmostSubform[0].Page1[0].Address_ReadOrder[0].f1_07[0]": [
          {
            required: true,
            message: "This field is required",
            trigger: "change",
          },
        ],
        "topmostSubform[0].Page1[0].Address_ReadOrder[0].f1_08[0]": [
          {
            required: true,
            message: "This field is required",
            trigger: "change",
          },
        ],
      };
    },
  },

  methods: {
    ...mapActions("auth", ["set_form_w9"]),

    goToBasicInfoTab() {
      this.$emit("changeTab", "basic_info");
    },

    prepareDataForSubmit(data) {
      const holder = {
        ...data,
      };

      switch (data.federal_tax_classification) {
        case "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[5]":
          holder["topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].f1_03[0]"] =
            data.tax_classification;
          break;
        case "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[6]":
          holder["topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].f1_04[0]"] =
            data.other;
          break;
        default:
          break;
      }

      holder[data.federal_tax_classification] = (
        +data.federal_tax_classification.slice(-2, -1) + 1
      ).toString();

      const ssn = data.ssn?.toString().replaceAll("-", "");
      if (ssn) {
        holder["topmostSubform[0].Page1[0].f1_11[0]"] = ssn.slice(0, 3);
        holder["topmostSubform[0].Page1[0].f1_12[0]"] = ssn.slice(3, 5);
        holder["topmostSubform[0].Page1[0].f1_13[0]"] = ssn.slice(5);
      }

      const ein = data.ein
        ?.toString()
        .replaceAll("-", "")
        .replaceAll("(", "")
        .replaceAll(")", "");
      if (ein) {
        holder["topmostSubform[0].Page1[0].f1_14[0]"] = ein.slice(0, 2);
        holder["topmostSubform[0].Page1[0].f1_15[0]"] = ein.slice(2);
      }

      holder["topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_2[0]"] =
        data.has_foreign_beneficiaries ? "1" : "Off";

      holder["employee_id"] = this.employee.id;
      holder["signature"] = data.signature;

      return holder;
    },

    onSubmit() {
      if (!this.formState.signature) {
        this.$message.error("Signature is required for submission.");
        return;
      }

      this.loading = true;
      this.errors = {};
      companyService
        .store(
          this.$constants.formW9Store,
          this.prepareDataForSubmit(this.formState)
        )
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.set_form_w9(res.data);
          }
        })
        .catch((err) => {
          this.errors = err.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    previewPDF() {
      if (!this.formState.signature) {
        this.$message.error("Signature is required for submission.");
        return;
      }

      this.$refs.formW9.validate().then(() => {
        this.pdfLoading = true;
        commonService
          .renderFile(
            this.$constants.formW9Preview,
            this.prepareDataForSubmit(this.formState),
            "application/pdf;base64"
          )
          .then((res) => {
            console.log({ res });
            // this.$message.success(res);
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.pdfLoading = false;
          });
      });
    },

    getFormData() {
      commonService
        .get(this.$constants.formW9, {})
        .then((res) => {
          this.formData = res.data["w-9"];
          this.oldData = res.data.user_data;
          if (this.oldData) {
            this.formState = {
              ...this.oldData.data,
            };
            if (
              this.oldData.data[
                "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[1]"
              ]
            ) {
              this.formState.federal_tax_classification =
                "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[1]";
            } else if (
              this.oldData.data[
                "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[2]"
              ]
            ) {
              this.formState.federal_tax_classification =
                "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[2]";
            } else if (
              this.oldData.data[
                "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[3]"
              ]
            ) {
              this.formState.federal_tax_classification =
                "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[3]";
            } else if (
              this.oldData.data[
                "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[4]"
              ]
            ) {
              this.formState.federal_tax_classification =
                "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[4]";
            } else if (
              this.oldData.data[
                "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[5]"
              ]
            ) {
              this.formState.federal_tax_classification =
                "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[5]";
              this.formState.tax_classification =
                this.oldData.data[
                  "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].f1_03[0]"
                ];
            } else if (
              this.oldData.data[
                "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[6]"
              ]
            ) {
              this.formState.federal_tax_classification =
                "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[6]";
              this.formState.other =
                this.oldData.data[
                  "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].f1_04[0]"
                ];
            }

            this.formState.has_foreign_beneficiaries =
              this.oldData.data[
                "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_2[0]"
              ] === "1";

            this.formState.ssn = this.oldData.data[
              "topmostSubform[0].Page1[0].f1_11[0]"
            ]
              ? `${this.oldData.data["topmostSubform[0].Page1[0].f1_11[0]"]}-${this.oldData.data["topmostSubform[0].Page1[0].f1_12[0]"]}-${this.oldData.data["topmostSubform[0].Page1[0].f1_13[0]"]}`
              : null;

            this.formState.ein = this.oldData.data[
              "topmostSubform[0].Page1[0].f1_14[0]"
            ]
              ? `(${this.oldData.data["topmostSubform[0].Page1[0].f1_14[0]"]}-${this.oldData.data["topmostSubform[0].Page1[0].f1_15[0]"]})`
              : null;

            setTimeout(() => {
              this.$refs.signaturePad.fromDataURL(this.formState.signature);
            }, 500);
          } else {
            this.formState["topmostSubform[0].Page1[0].f1_01[0]"] =
              this.employee?.fl_name;
            this.formState[
              "topmostSubform[0].Page1[0].Address_ReadOrder[0].f1_07[0]"
            ] = this.employee?.street_address;
            this.formState[
              "topmostSubform[0].Page1[0].Address_ReadOrder[0].f1_08[0]"
            ] = `${this.employee?.city}, ${this.employee?.state}, ${this.employee?.zipcode}`;
            this.formState["ssn"] = this.employee?.social_security_no;

            this.formState.hire_date = moment(this.employee?.hire_date).format(
              "MM/DD/YYYY"
            );
          }
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        });
    },

    clear() {
      this.formState.signature = null;
      this.$refs.signaturePad.clearSignature();
    },

    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.formState.signature = data;
      return Promise.resolve();
    },
  },
};
</script>
