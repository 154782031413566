<template>
  <a-card title="Monthly Billing" size="small" :loading="loading">
    <a-form :model="model" @finish="onSubmit">
      <a-checkbox
        style="padding-left: 120px"
        class="mb-sm-1"
        v-model:checked="isEnabled"
      >
        Enable Function
      </a-checkbox>
      <template v-if="isEnabled">
        <a-row class="mb-sm-1" :gutter="[10, 10]" type="flex" align="middle">
          <!--    HEADING    -->
          <a-col flex="120px" />
          <a-col flex="200px">
            <a-typography-text strong>Billing Types</a-typography-text>
          </a-col>
          <a-col flex="200px">
            <a-typography-text strong>
              Recurring Billing Date
            </a-typography-text>
          </a-col>
          <a-col flex="auto">
            <a-space>
              <a-typography-text strong> Employees</a-typography-text>
              <Employees
                v-model:employee_ids="commonEmployees"
                isCommonEmployeeSelection
                :monthly-billing-employees="billingEmployees"
              />
            </a-space>
          </a-col>
        </a-row>

        <template
          v-for="(item, idx) in model.master_hospital_billing"
          :key="idx"
        >
          <a-row :gutter="[10, 10]" type="flex">
            <a-col flex="120px">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  gap: 4px;
                "
              >
                <a-button
                  v-if="idx === model.master_hospital_billing.length - 1"
                  @click="addRow"
                  style="color: green"
                >
                  <PlusOutlined />
                </a-button>
                <a-button
                  @click="deleteRow(idx)"
                  style="color: red"
                  v-if="model.master_hospital_billing.length > 1"
                >
                  <MinusOutlined />
                </a-button>
              </div>
            </a-col>
            <a-col flex="200px">
              <a-form-item
                :name="[
                  'master_hospital_billing',
                  idx,
                  'master_billing_type_id',
                ]"
                :rules="{
                  required: true,
                  message: 'This field is required',
                  type: 'number',
                }"
              >
                <a-select
                  v-model:value="item.master_billing_type_id"
                  placeholder="Select a type"
                  :options="masterBillingTypes"
                />
              </a-form-item>
            </a-col>
            <a-col flex="200px">
              <a-form-item
                :name="['master_hospital_billing', idx, 'billing_date']"
                :rules="{
                  required: true,
                  message: 'This field is required',
                  type: 'number',
                }"
              >
                <a-select
                  v-model:value="item.billing_date"
                  placeholder="Select a date"
                  :options="days"
                />
              </a-form-item>
            </a-col>
            <a-col flex="auto">
              <Employees
                v-model:employee_ids="item.employee_ids"
                :monthly-billing-employees="billingEmployees"
              />
              <!--              <a-form-item
              :name="['master_hospital_billing', idx, 'employee_ids']"
              :rules="[
                {
                  message: 'Select at least one employee',
                  validator: employeeArrValidator,
                },
              ]"
            >
              <a-select
                v-model:value="item.employee_ids"
                placeholder="Select employees"
                mode="multiple"
                :options="employees"
              />
            </a-form-item>-->
            </a-col>
          </a-row>
        </template>
      </template>

      <a-row :gutter="[10, 10]" type="flex" class="mt-sm-1">
        <a-col flex="120px" />
        <a-col>
          <a-button type="primary" :loading="submitBtnLoader" htmlType="submit">
            Submit
          </a-button>
        </a-col>
      </a-row>
    </a-form>
  </a-card>
</template>

<script>
import { MinusOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { commonService } from "../../../services";
import Employees from "../components/Employees.vue";

export default {
  props: {
    hospital: {
      required: true,
    },
  },

  components: {
    MinusOutlined,
    PlusOutlined,
    Employees,
  },

  data() {
    return {
      loading: true,
      isEnabled: false,
      model: {
        hospital_id: this.hospital.id,
        master_hospital_billing: [
          {
            id: null,
            master_billing_type_id: null,
            billing_date: null,
            employee_ids: [],
          },
        ],
      },
      rules: {},
      masterBillingTypes: [],
      submitBtnLoader: false,
      billingEmployees: [],
      days: Array.from({ length: 31 }, (_, i) => ({
        label: i + 1,
        value: i + 1,
      })),
    };
  },

  async mounted() {
    await Promise.all([
      this.fetchMasterBillingTypes(),
      this.fetchEmployees(),
      this.fetchRecord(),
    ]);
  },

  computed: {
    employees() {
      return this.billingEmployees.map((emp) => ({
        label: emp.name,
        value: emp.id,
      }));
    },
    commonEmployees: {
      get() {
        // get common employees between all records
        return this.model.master_hospital_billing.reduce((acc, item) => {
          return acc.filter((empId) => item.employee_ids.includes(empId));
        }, this.model.master_hospital_billing[0]?.employee_ids ?? []);
      },
      set(value) {
        const { removeIds, addIds } = value;
        this.model.master_hospital_billing.forEach((item) => {
          addIds.forEach((empId) => {
            if (!item.employee_ids.includes(empId)) {
              item.employee_ids.push(empId);
            }
          });

          removeIds.forEach((empId) => {
            item.employee_ids = item.employee_ids.filter((id) => id !== empId);
          });
        });
      },
    },
  },

  methods: {
    fetchRecord() {
      this.loading = true;
      return commonService
        .get(this.$constants.getHospitalBillingTypes, {
          hospital_id: this.hospital.id,
        })
        .then((res) => {
          this.setMasterHospitalBilling(res.data);
        })
        .finally(() => (this.loading = false));
    },

    setMasterHospitalBilling(data) {
      if (data.length > 0) {
        this.isEnabled = true;
        this.model.master_hospital_billing = data
          .filter((e) => e.status === 1)
          .map((item) => ({
            id: item.id,
            master_billing_type_id: item.master_billing_type_id,
            billing_date: item.billing_date,
            employee_ids: item.employees
              .filter((emp) => emp.status === 1)
              .map((emp) => emp.employee_id),
          }));
      }
      if (this.model.master_hospital_billing.length == 0) {
        this.model.master_hospital_billing = [
          {
            id: null,
            master_billing_type_id: null,
            billing_date: null,
            employee_ids: [],
          },
        ];
      }
    },

    fetchMasterBillingTypes() {
      return commonService
        .get(this.$constants.getMasterBillingTypes)
        .then((res) => {
          this.masterBillingTypes = res.data.map((item) => ({
            label: item.name,
            value: item.id,
          }));
        });
    },
    fetchEmployees() {
      return commonService
        .get(this.$constants.getAssociatedEmployees)
        .then((res) => {
          this.billingEmployees = res.data;
        });
    },
    addRow() {
      this.model.master_hospital_billing.push({
        id: null,
        master_billing_type_id: null,
        billing_date: null,
        employee_ids: [],
      });
    },
    deleteRow(index) {
      this.model.master_hospital_billing.splice(index, 1);
    },
    getUniqueTypes(currentRecord) {
      const selectedTypes = this.model.master_hospital_billing.map(
        (item) => item.master_billing_type_id
      );

      return this.masterBillingTypes.filter(
        (item) =>
          item.value === currentRecord.master_billing_type_id ||
          !selectedTypes.includes(item.value)
      );
    },
    employeeArrValidator(rule, value) {
      if (value.length === 0) {
        return Promise.reject("Select at least one employee");
      }

      return Promise.resolve();
    },

    onSubmit() {
      this.submitBtnLoader = true;
      const data = this.model;
      if (!this.isEnabled) {
        data.master_hospital_billing = [];
      }

      commonService
        .store(this.$constants.storeMasterBilling, data)
        .then((res) => {
          this.setMasterHospitalBilling(res.data);
          this.$message.success(res.message);
        })
        .finally((err) => {
          this.submitBtnLoader = false;
        });
    },
  },
};
</script>
