<template>
  <div>
    <a-row type="flex" justify="center" class="mb-sm-1">
      <a-button htmlType="button" type="primary" danger ghost @click="clear">
        Clear
      </a-button>
    </a-row>
    <a-row type="flex" justify="center">
      <vue-signature-pad
        ref="signaturePad"
        :options="{
          onEnd: save,
        }"
        :customStyle="$constants.signPadCustomCss"
      />
    </a-row>
  </div>
</template>

<script>
export default {
  name: "signature-pad",

  props: {
    model: {
      require: true,
    },
  },

  mounted() {
    this.$refs.signaturePad.fromDataURL(this.model.section8[0].sign);
  },

  methods: {
    clear() {
      this.model.section8[0].sign = null;
      this.$refs.signaturePad.clearSignature();
    },

    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      this.model.section8[0].sign = data;
    },
  },
};
</script>