<template>
  <a-layout-content>
    <a-row :gutter="[10, 10]">
      <a-col :span="24">
        <page-header>
          <template #title> Monthly Customer Billing Checklist </template>
        </page-header>
      </a-col>

      <a-col :span="24">
        <a-card size="small">
          <a-row type="flex" :gutter="[10, 10]">
            <a-col flex="auto">
              <a-input
                v-model:value="query.hospital"
                placeholder="Search Hospital"
                style="width: 100%"
              />
            </a-col>

            <a-col flex="200px">
              <a-month-picker
                v-model:value="query.month"
                placeholder="Select month"
                format="MMMM, YYYY"
                :disabled-date="$comman.disabledFutureDate"
                style="width: 100%"
                @change="fetchData"
              />
            </a-col>
            <a-col flex="140px">
              <a-button
                block
                @click="handleExportEvent"
                :loading="excelExportLoading"
                class="primary-color-btn"
              >
                <a-space>
                  <i class="ti ti-file-spreadsheet ti-lg"></i>
                  Export Excel
                </a-space>
              </a-button>
            </a-col>
            <a-col flex="200px" v-if="!!nextMonthBilling">
              <a-popconfirm
                :title="`Are you sure you want to generate billing for ${nextMonthBillingFormat}?`"
                ok-text="Yes"
                :ok-button-props="{
                  loading: nextMonthBillingLoading,
                }"
                cancel-text="No"
                @confirm="createNextMonthBilling"
              >
                <a-button
                  block
                  :disabled="nextMonthBillingLoading"
                  class="secondary-color-btn"
                >
                  <a-space>
                    <i class="ti ti-rocket ti-lg"></i>
                    Generate Billing for {{ nextMonthBillingFormat }}
                  </a-space>
                </a-button>
              </a-popconfirm>
            </a-col>
          </a-row>
        </a-card>
      </a-col>

      <a-col :span="24">
        <a-card
          size="small"
          :loading="loading"
          :tabList="tabList"
          :activeTabKey="activeTabKey"
          @tabChange="(key) => (activeTabKey = key)"
        >
          <template #tabBarExtraContent>
            <a-button
              v-if="isAllExpanded"
              type="primary"
              @click="collapseAll"
              :disabled="!expandedRows.length"
            >
              <a-space>
                <i class="ti ti-chevron-up ti-lg"></i> Collapse All
              </a-space>
            </a-button>
            <a-button
              v-else
              type="primary"
              @click="expandAll"
              :disabled="isAllExpanded"
            >
              <a-space>
                <i class="ti ti-chevron-down ti-lg"></i> Expand All
              </a-space>
            </a-button>
          </template>

          <template #customRender="item">
            {{ item.text }} ({{ item.counts }})
          </template>

          <div id="tableContainer" ref="myTable">
            <a-table
              :dataSource="tableDataGrouped[activeTabKey]"
              bordered
              :expandedRowKeys="expandedRows"
              :columns="columns"
              size="small"
              :pagination="false"
              :rowKey="(record, index) => record.id"
            >
              <template #expandIcon="{ expanded, onExpand, record }">
                <a-button
                  size="small"
                  type="text"
                  @click="() => handleExpand(record, onExpand, expanded)"
                >
                  <i v-if="expanded" class="ti ti-chevron-down"></i>
                  <i v-else class="ti ti-chevron-right"></i>
                </a-button>
              </template>

              <template #expandedRowRender="{ record }">
                <div class="extended-row">
                  <a-table
                    :columns="innerColumns"
                    :data-source="record.items"
                    size="small"
                    :pagination="false"
                    :style="{
                      width: `calc(${containerWidth}px - 40px)`,
                    }"
                    :scroll="{ x: true }"
                    :rowKey="(rec, index) => rec.id"
                  >
                    <template #subitem="{ index, record: rec }">
                      <SubItem
                        v-model:record="record.items[index]"
                        @onCommentAdd="addComment(rec, false)"
                      />
                    </template>

                    <template #date="{ record: rec }">
                      <SubDate
                        v-model:billing_date="rec.billing_date"
                        :record="rec"
                      />
                    </template>

                    <template #owner="{ index }">
                      <Owner
                        v-model:record="record.items[index]"
                        :employees="associatedEmployees"
                      />
                    </template>

                    <template #status="{ record: rec }">
                      <StatusCell
                        v-model:status="rec.status"
                        :record="rec"
                        v-model:parent="record.status"
                        isChild
                      />
                    </template>

                    <template #text="{ record }">
                      <TableTextInput
                        v-model:text="record.notes"
                        recordKey="notes"
                        :record="record"
                        isChild
                      />
                    </template>

                    <template #qbo_invoice_number="{ record }">
                      <TableTextInput
                        v-model:text="record.qbo_invoice_number"
                        recordKey="qbo_invoice_number"
                        :record="record"
                      />
                    </template>
                  </a-table>
                </div>
              </template>

              <template #customerName="{ record }">
                <CustomerName
                  :record="record"
                  @onCommentAdd="addComment(record, true)"
                />
              </template>

              <template #invoiceCreationDate="{ record: rec, index }">
                <InvoiceCreationDate
                  v-model:billing_date="rec.billing_date"
                  :record="rec"
                />
              </template>

              <template #responsibleBillingParty="{ record, index }">
                <ResponsibleBillingParty
                  :record="data[index]"
                  :employees="employees"
                />
              </template>

              <template #status="{ record }">
                <StatusSplit :record="record" />
              </template>

              <template #notes="{ record }">
                <TableTextInput
                  v-model:text="record.notes"
                  recordKey="notes"
                  :record="record"
                />
              </template>
            </a-table>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </a-layout-content>
</template>

<script setup>
import {
  CustomerName,
  InvoiceCreationDate,
  Owner,
  ResponsibleBillingParty,
  Status as StatusCell,
  StatusSplit,
  SubDate,
  SubItem,
  Text as TableTextInput,
} from "@/components/monthlyCustomerInvoicing";
import { constants } from "@/helper/constants";
import { commonService } from "@/services";
import { message } from "ant-design-vue";
import moment from "moment";
import { computed, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

const columns = [
  {
    title: "Customer",
    slots: {
      customRender: "customerName",
    },
  },
  {
    title: "Invoice Creation Date",
    slots: {
      customRender: "invoiceCreationDate",
    },
    width: 170,
  },
  {
    title: "Responsible Party",
    slots: {
      customRender: "responsibleBillingParty",
    },
  },
  {
    title: "Status",
    slots: {
      customRender: "status",
    },
    width: 180,
    customCell: (record) => {
      return {
        style: {
          padding: "0",
          height: "1px",
        },
      };
    },
  },
  {
    title: "Notes",
    slots: {
      customRender: "notes",
    },
    width: 230,
  },
];

const innerColumns = [
  {
    title: "Subitem",
    slots: {
      customRender: "subitem",
    },
    width: 300,
  },
  {
    title: "Date",
    slots: {
      customRender: "date",
    },
    width: 200,
  },
  {
    title: "Owner",
    slots: {
      customRender: "owner",
    },
  },
  {
    title: "Status",
    slots: {
      customRender: "status",
    },
    width: 150,
    customCell: (record) => {
      return {
        style: {
          padding: "0",
          height: "1px",
        },
      };
    },
  },
  {
    title: "QBO Invoice Number",
    slots: {
      customRender: "qbo_invoice_number",
    },
    width: 230,
  },
  {
    title: "Notes",
    slots: {
      customRender: "text",
    },
    width: 230,
  },
];

const employees = ref([]);
const data = ref([]);
const dataFiltered = computed(() => {
  return data.value.filter((item) => {
    if (!query.hospital) return true;
    return item.hospital.name
      .toLowerCase()
      .includes(query.hospital.toLowerCase());
  });
});

const route = useRoute();
const router = useRouter();
const query = reactive({
  month: route.query.month ? moment(route.query.month, "YYYY-MM") : moment(),
  hospital: null,
});
const loading = ref(false);
const nextMonthBilling = ref(null);
const nextMonthBillingFormat = computed(() => {
  if (!nextMonthBilling.value) return null;
  return moment(nextMonthBilling.value).format("MMMM, YYYY");
});
const nextMonthBillingLoading = ref(false);
function fetchData() {
  loading.value = true;
  return commonService
    .store(constants.getMonthlyBillingData, {
      month: query.month.format("YYYY-MM"),
    })
    .then((response) => {
      data.value = response.data;
      nextMonthBilling.value = response.create_new_month_billing;
      router.push({
        query: {
          month: query.month.format("YYYY-MM"),
        },
      });
    })
    .finally(() => {
      loading.value = false;
    });
}
function createNextMonthBilling() {
  nextMonthBillingLoading.value = true;
  commonService
    .store(constants.createNextMonthBilling, {
      month: nextMonthBilling.value,
    })
    .then(() => {
      message.success("Next month billing created successfully");

      const selectedMonth = query.month.format("YYYY-MM");
      const nextMonth = nextMonthBilling.value;

      if (selectedMonth === nextMonth) {
        fetchData();
      }
    })
    .finally(() => {
      nextMonthBillingLoading.value = false;
    });
}

const associatedEmployees = ref([]);

function fetchAssociatedEmployees() {
  commonService.store(constants.getAssociatedEmployees).then((response) => {
    associatedEmployees.value = response.data;
  });
}

onMounted(() => {
  Promise.all([
    commonService.get(constants.getEmployeesUrl),
    fetchData(),
    fetchAssociatedEmployees(),
  ]).then(([employeesRes]) => {
    employees.value = employeesRes.data;
  });
});

const CustomerInvoiceStatus = {
  NotStarted: 0,
  WorkingOnIt: 1,
  Stuck: 2,
  Done: 3,
  // NoActivity: 4,
  NoBilling: 4,
};

const excelExportLoading = ref(false);

function handleExportEvent() {
  let url = constants.monthlyBillingExportExcel;
  const monthName = query.month.format("MMMM_YYYY");
  let fileName = `${monthName}_CustomerInvoicing.xlsx`;

  var params = {
    month: query.month.format("YYYY-MM"),
  };

  excelExportLoading.value = true;
  commonService
    .downloadFile(url, params, fileName)
    .then((res) => {
      message.success(res);
    })
    .catch((err) => {
      message.error(err);
    })
    .finally(() => {
      excelExportLoading.value = false;
    });
}

const expandedRows = ref([]);

function handleExpand(record, onExpand, expanded) {
  if (expanded) {
    expandedRows.value = expandedRows.value.filter((id) => id !== record.id);
  } else {
    expandedRows.value = [...expandedRows.value, record.id];
  }
  onExpand(record);
}

const isAllExpanded = computed(() => {
  return data.value.length === expandedRows.value.length;
});

function collapseAll() {
  expandedRows.value = [];
}

function expandAll() {
  expandedRows.value = data.value.map((item) => item.id);
}

const store = useStore();

function addComment(record, isMain) {
  store.dispatch("drawer/open", {
    title: "Add Comment",
    path: "finance.monthlyCustomerInvoicing.drawer.AddComment",
    callback: (newComments) => {
      record.comments = newComments;
    },
    record,
    extra: { isMain },
  });
}

const activeTabKey = ref(CustomerInvoiceStatus.NotStarted.toString());
const tabList = computed(() => [
  {
    text: "Pending",
    key: CustomerInvoiceStatus.NotStarted.toString(),
    counts: tableDataGrouped.value[CustomerInvoiceStatus.NotStarted].length,
    slots: { tab: "customRender" },
  },
  {
    text: "Done",
    key: CustomerInvoiceStatus.Done.toString(),
    counts: tableDataGrouped.value[CustomerInvoiceStatus.Done].length,
    slots: { tab: "customRender" },
  },
]);

const tableDataGrouped = computed(() => {
  return dataFiltered.value.reduce(
    (acc, item) => {
      if (item.status === CustomerInvoiceStatus.NotStarted) {
        acc[CustomerInvoiceStatus.NotStarted].push(item);
      } else {
        acc[CustomerInvoiceStatus.Done].push(item);
      }
      return acc;
    },
    {
      [CustomerInvoiceStatus.NotStarted]: [],
      [CustomerInvoiceStatus.Done]: [],
    }
  );
});

const containerWidth = ref(0);
onMounted(() => {
  const container = document.getElementById("tableContainer");
  if (container) {
    containerWidth.value = container.getBoundingClientRect().width;
  }

  window.addEventListener("resize", () => {
    const container = document.getElementById("tableContainer");
    if (container) {
      containerWidth.value = container.getBoundingClientRect().width;
    }
  });
});
</script>

<style lang="less">
.extended-row {
  .ant-table-wrapper {
    margin: 10px 0 10px -30px;
  }
}

.ant-table-expanded-row > td:first-child {
  border-right: none !important;
}
</style>
