<template>
  <a-layout-content>
    <page-header>
      <template #title> Human Resource Dashboard </template>

      <template #buttons>
        <!--  -->
      </template>
    </page-header>

    <a-card title="Top 10 Per Diem Employees" size="small">
      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.employee_id"
        :dataSource="fteEmployees"
        :pagination="false"
        :loading="ftpEmployeeTableLoading"
        size="small"
        :scroll="scroll"
      />
    </a-card>
  </a-layout-content>
</template>

<script>
import { commonService } from "../../services";
export default {
  data() {
    return {
      fteEmployees: [],
      ftpEmployeeTableLoading: true,
      columns: [
        {
          title: "Employee",
          dataIndex: "name",
          key: "name",
          sorter: (a, b) => (a.name > b.name ? 1 : -1),
        },
        {
          title: "Employment Date",
          dataIndex: "hire_date",
          key: "hire_date",
          customRender: ({ text }) =>
            text ? this.$customDate.mdy(text) : "N/A",
        },
        {
          title: "No. of Weeks",
          dataIndex: "no_of_weeks",
          key: "no_of_weeks",
          sorter: (a, b) => (a.no_of_weeks > b.no_of_weeks ? 1 : -1),
        },
        {
          title: "FTE Score",
          dataIndex: "avg_fte",
          key: "avg_fte",
          sorter: (a, b) => (a.avg_fte > b.avg_fte ? 1 : -1),
        },
      ],
    };
  },

  mounted() {
    this.getFteEmployees();
  },

  methods: {
    getFteEmployees() {
      this.ftpEmployeeTableLoading = true;
      commonService
        .get(this.$constants.hrTopFteEmployeeListUrl)
        .then(async (res) => {
          this.fteEmployees = res.data;
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.ftpEmployeeTableLoading = false;
        });
    },
  },
};
</script>
