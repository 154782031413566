<template>
  <div class="offer-letter">
    <img
      class="my-sm-2"
      src="./../../assets/LogoKeystone.png"
      alt=""
      width="250"
    />

    <!-- OTP VERIFICATION COMPONENT -->
    <template v-if="currentStep == 1">
      <a-card
        size="small"
        :loading="loading"
        class="otp-card"
        style="min-width: 400px"
      >
        <template v-if="!error" #title>Sign Offer Letter</template>

        <a-alert :message="otpMessage" type="success" class="mb-sm-1">
        </a-alert>

        <a-form
          :model="model"
          :rules="rules"
          layout="vertical"
          @finish="onSubmit"
        >
          <a-form-item ref="email" label="Email" name="email">
            <a-input v-model:value="model.email" disabled />
          </a-form-item>

          <a-form-item
            ref="otp"
            label="One-Time Verification Code"
            name="otp"
            extra="Example: 123456"
          >
            <a-input-number type="number" v-model:value="model.otp" />
          </a-form-item>

          <a-button
            size="large"
            type="primary"
            html-type="submit"
            :loading="submitting"
          >
            Verify OTP
          </a-button>
        </a-form>
      </a-card>
    </template>

    <!-- OFFER LETTER SIGNATURE COMPONENT -->
    <template v-if="currentStep == 2">
      <a-card size="small" class="preview-card">
        <a-row>
          <a-col :lg="12" :md="24">
            <a-empty v-if="!pdfPreviewURL">
              <template #description>
                <span> Offer letter preview will be shown here </span>
              </template>
            </a-empty>

            <template v-else>
              <iframe
                :src="pdfPreviewURL + '#toolbar=0&navpanes=0&scrollbar=0'"
                type="application/pdf"
                width="100%"
                @click.right.stop.prevent
              />
            </template>
          </a-col>

          <a-col :lg="12" :md="24">
            <signature-box class="mx-sm-1" v-model:sign="sign">
              <template #note>
                <a-typography-paragraph>
                  I, {{ candidate.first_name }} {{ candidate.last_name }},
                  acknowledge that I have carefully reviewed and fully
                  understand the terms and conditions outlined in the employment
                  offer letter and employment agreement provided to me by
                  Keystone Perfusion Services, PC. I am voluntarily agreeing to
                  these terms, and I understand that this digital signature
                  carries the same legal weight as a physical signature.
                  <br />
                  By signing above, I confirm my acceptance of this offer and my
                  commitment to join Keystone Perfusion Services, PC as
                  described in the agreement.
                </a-typography-paragraph>
              </template>

              <template #buttons>
                <a-button
                  type="primary"
                  html-type="button"
                  @click="submitSign"
                  :loading="submitting"
                >
                  Accept & Submit
                </a-button>

                <a-button
                  danger
                  type="primary"
                  @click="declineOfferModalOpen"
                  :loading="submitting"
                >
                  Decline Offer
                </a-button>
              </template>
            </signature-box>
          </a-col>
        </a-row>

        <a-form
          :model="declineForm"
          layout="vertical"
          @finish="handleDeclineOffer"
        >
          <a-modal
            v-model:visible="showDeclineModal"
            centered
            closable
            :maskClosable="false"
            :footer="false"
            :keyboard="false"
            title="Decline Offer Letter"
          >
            <a-form-item ref="remarks" label="Remarks" name="remarks">
              <a-textarea
                v-model:value="declineForm.remarks"
                :auto-size="{ minRows: 3, maxRows: 6 }"
                placeholder="Enter here..."
              />
            </a-form-item>

            <a-row class="mt-sm-2" :gutter="[10]" justify="end">
              <a-col>
                <a-button
                  type="primary"
                  danger
                  html-type="submit"
                  @click="handleDeclineOffer"
                  :loading="submitting"
                >
                  Decline
                </a-button>
              </a-col>

              <a-col>
                <a-button
                  @click="showDeclineModal = false"
                  :loading="submitting"
                >
                  Cancel
                </a-button>
              </a-col>
            </a-row>
          </a-modal>
        </a-form>
      </a-card>
    </template>

    <!-- THANK YOU COMPONENT -->
    <template v-if="currentStep == 3">
      <a-card size="small" class="thank-you-card">
        <a-result
          status="success"
          title="Thank you!"
          sub-title="You've successfully signed your employment offer letter/agreement with Keystone Perfusion Services, PC. We'll be in touch soon with the next steps in the onboarding process. Welcome to the team!"
        >
        </a-result>
      </a-card>
    </template>

    <!-- ERROR COMPONENT -->
    <template v-if="currentStep == 4">
      <a-card size="small" class="error-card">
        <a-result status="error" :title="error">
          <template #subTitle>
            Looks like something went wrong! But don't worry; it can happen to
            best of us, and it just happened to you.
          </template>
        </a-result>
      </a-card>
    </template>

    <!-- OFFER DECLINE COMPONENT -->
    <template v-if="currentStep == 5">
      <a-card size="small" class="error-card">
        <a-result status="error" title="Offer Rejected!">
          <template #icon>
            <i
              style="font-size: 64px; color: #ff4d4f"
              class="ti ti-mood-sad ti-xl"
            ></i>
          </template>

          <template #subTitle>
            We're sorry to hear that you've decided to decline the offer. If you
            have any feedback or would like to discuss further, please feel free
            to reach out to us.
          </template>
        </a-result>
      </a-card>
    </template>
  </div>
</template>

<script>
import CloseCircleOutlined from "@/App.vue";
import signatureBox from "../../components/signatureBox.vue";
import { commonService } from "../../services";

export default {
  components: { CloseCircleOutlined, signatureBox },

  data() {
    return {
      otpMessage: null,
      loading: true,
      currentStep: 1,
      submitting: false,
      showDeclineModal: false,
      otpVerifed: false,
      error: null,
      candidate: null,
      previewBtnLoader: false,
      pdfPreviewURL: null,
      sign: null,
      model: {
        email: this.$route.query?.email ?? "",
        token: this.$route.params.token,
        otp: "",
      },
      declineForm: {
        remarks: null,
      },
      rules: {
        email: {
          required: true,
          message: "This field is required!",
        },
        otp: {
          required: true,
          message: "OTP is required!",
          validator: this.$validation.otpValidation,
        },
      },
    };
  },

  mounted() {
    this.sendOtp();
  },

  methods: {
    sendOtp() {
      commonService
        .store(this.$constants.sendOfferLetterOtpUrl, {
          email: this.model.email,
          token: this.model.token,
        })
        .then((res) => {
          if (res.data?.candidate) {
            this.candidate = res.data.candidate;
            if (this.candidate.status === 6) {
              this.currentStep = 5;
            } else {
              this.currentStep = 3;
            }
          } else {
            this.otpMessage = res.message;
            // this.$message.success(res.message);
          }
        })
        .catch((err) => {
          this.error = err;
          this.currentStep = 4;
        })
        .finally((err) => {
          this.loading = false;
        });
    },

    handleDeclineOffer() {
      this.submitting = true;
      commonService
        .store(this.$constants.offerLetterDeclineUrl, {
          email: this.model.email,
          token: this.model.token,
          decline_remark: this.declineForm.remarks,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.currentStep = 5;
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally((err) => {
          this.submitting = false;
        });
    },

    onSubmit() {
      this.submitting = true;
      commonService
        .store(this.$constants.offerLetterOtpVerificationUrl, {
          email: this.model.email,
          token: this.model.token,
          otp: this.model.otp,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.otpVerifed = true;
          this.currentStep++;
          this.candidate = res.data.candidate;
          this.previewPDF();
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally((err) => {
          this.submitting = false;
        });
    },

    previewPDF() {
      this.previewBtnLoader = true;
      commonService
        .downloadFile(
          this.$constants.offerLetterPreviewUrl,
          { id: this.candidate.id },
          "Offer Letter.pdf",
          true
        )
        .then((res) => {
          this.pdfPreviewURL = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/pdf",
            })
          );
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.previewBtnLoader = false;
        });
    },

    declineOfferModalOpen() {
      this.showDeclineModal = true;
    },

    submitSign() {
      if (this.sign == null) {
        this.$message.error("Candidate signature is required for submission.");
        return;
      }

      this.submitting = true;
      commonService
        .store(this.$constants.offerLetterSignUrl, {
          email: this.model.email,
          token: this.model.token,
          sign: this.sign,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.currentStep++;
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally((err) => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style lang="less">
.offer-letter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ant-col {
    width: 100%;
  }

  .ant-card.thank-you-card,
  .ant-card.otp-card,
  .ant-card.error-card {
    max-width: 600px;
    margin: 0 10px;
  }

  .ant-card.preview-card {
    width: 100%;

    iframe {
      height: calc(100vh - 153px);
    }

    .ant-typography {
      max-width: 600px;
      text-align: justify;
    }
  }
}
</style>
